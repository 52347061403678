import { useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import PortalAnalytics from "PortalAnalytics";

export const MODAL_QUERY_PARAM_NAME = "modal";

const VALID_MODALS = [
    "default-currency",
    "report-issue",
    "request-supplier-login",
    "certification-quiz",
    "add-client",
    "test"
];

export const isValidModal = (id?: string | null): boolean => !!id && VALID_MODALS.includes(id);

interface UseModalManagerReturn {
    modalId?: string | undefined;
    /** sometimes you may be updating a query within a modal and passing false to close modal will prevent race conditions where this will use the old query params
     *  if using this, make sure to delete the modalId from the query params to close the modal
     */
    closeModal: (updateQuery?: boolean) => void;
    openModal: (modalId: string) => void;
}

export const useModalManager = (): UseModalManagerReturn => {
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const modalId = searchParams?.get(MODAL_QUERY_PARAM_NAME);

    const isValid = isValidModal(modalId);

    const updateURL = (queryParams: URLSearchParams) => {
        const queryString = queryParams.toString();
        // Notice that {scroll: false} will fail until we upgrade NextJS
        router.replace(`${pathname}${queryString ? `?${queryString}` : ""}`, { scroll: false });
    };
    const closeModal = (updateQuery = true) => {
        const newSearchParams = new URLSearchParams(searchParams ?? "");
        PortalAnalytics.track("modal__track_close", { modalId });
        newSearchParams.delete(MODAL_QUERY_PARAM_NAME);
        if (updateQuery) {
            updateURL(newSearchParams);
        }
    };
    const openModal = (modalId: string) => {
        const newSearchParams = new URLSearchParams(searchParams ?? "");
        newSearchParams.set(MODAL_QUERY_PARAM_NAME, modalId);
        updateURL(newSearchParams);
        PortalAnalytics.track("modal__track_open", { modalId });
    };
    useEffect(() => {
        if (!isValid && modalId) {
            PortalAnalytics.track("modal__track_invalid", { modalId });
        }
    }, [isValid, modalId]);

    return { modalId: (isValid && modalId) || undefined, closeModal, openModal };
};
