import React, { useEffect, useState } from "react";
import App, { AppContext, AppProps } from "next/app";
import Head from "next/head";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { SessionProvider } from "next-auth/react";
import getConfig from "next/config";
import { useRouter } from "next/router";
import Script from "next/script";
import { ThemeProvider } from "@material-tailwind/react";
import PortalAnalytics from "analytics/PortalAnalytics";
import PrivateRoute from "components/common/PrivateRoute";
import { URI_LOGIN, URI_REGISTRATION_SUCCESS } from "constants/urls";
import { StoreProvider } from "store/index";
import uiConfig from "ui-static-data.json";
import { usePreserveScroll } from "components/vendor/hooks/use-preserve-scroll";
import DatadogUserManager from "components/common/DatadogUserManager";
import "styles/fonts.css";
import "styles/globals.css";
import "styles/react-datepicker-global.scss";
// FIXME: These next 2 lines need to move to SchedulerContent.tsx and CalendarCardContent.tsx but the pipeline is breaking when it finds them
import "react-big-calendar/lib/css/react-big-calendar.css";
import "styles/fora-react-big-calendar.css";
import { ModalManager } from "../components/modal";
import { GlobalErrorBoundary } from "components/GlobalErrorBoundary";
import { SWRContext } from "contexts/SWRContext";

const { publicRuntimeConfig } = getConfig();
const segmentTrackingId = publicRuntimeConfig.segmentTrackingId;
const tokenExSource = publicRuntimeConfig.tokenExSource;
const nextDebugMode = publicRuntimeConfig.nextDebugMode !== "false";
const enableDataDogRUM = publicRuntimeConfig.enableDataDogRUM === "true";
const ddService = publicRuntimeConfig.ddService as string;
const ddVersion = publicRuntimeConfig.ddVersion as string;
const ddEnv = publicRuntimeConfig.ddEnv as string;

function ForaApp({ Component, pageProps, isMobileView }: AppProps & { isMobileView: boolean }) {
    const [appState, setAppState] = useState<any>();

    const [datadogLogsAndRumHandler, setDatadogLogsAndRumHandler] = useState<any>(null);

    const router = useRouter();

    usePreserveScroll();

    const unProtectedRoutes = [
        URI_REGISTRATION_SUCCESS,
        "/register/[registrationKey]", // like directories structure in pages/
        "/register/capture",
        "/register-v1/[registrationKey]", // like directories structure in pages/
        URI_LOGIN,
        "/login-as/[[...accessToken]]",
        "/stripe",
        "/stripe/landing",
        "/clients/temp-add-card-by-link/[link]",
        "/travel-review/[...id]"
    ];
    useEffect(() => {
        document.body.className = pageProps.isEmptyTemplate
            ? "h-full"
            : "antialiased h-full lg:flex md:overflow-x-hidden";
        if (!nextDebugMode) {
            if (!datadogLogsAndRumHandler) {
                initDataDogLogsAndRum();
            }
        }

        return () => {
            if (!nextDebugMode && enableDataDogRUM) {
                datadogLogsAndRumHandler?.stopSessionReplayRecording();
            }
        };
    }, []);

    const handleRouteChangeComplete = (url: string) => {
        PortalAnalytics.page(document.title, {
            url: url,
            router: router.pathname
        });
    };
    useEffect(() => {
        if (!router.isReady) return;

        router.events.on("routeChangeComplete", handleRouteChangeComplete);

        return () => {
            router.events.off("routeChangeComplete", handleRouteChangeComplete);
        };
    }, [router.isReady]);

    const initDataDogLogsAndRum = () => {
        if (enableDataDogRUM) {
            datadogRum.init({
                applicationId: publicRuntimeConfig.ddApplicationId as string,
                clientToken: publicRuntimeConfig.ddClientToken as string,
                site: "datadoghq.com",
                service: ddService,
                version: ddVersion,
                env: ddEnv,
                allowedTracingUrls: [
                    {
                        match: publicRuntimeConfig.nextBase,
                        propagatorTypes: ["tracecontext", "datadog"]
                    }
                ],
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                defaultPrivacyLevel: "mask-user-input",
                silentMultipleInit: true
            });
            datadogRum.startSessionReplayRecording();
        }
        datadogLogs.init({
            clientToken: publicRuntimeConfig.ddClientToken as string,
            site: "datadoghq.com",
            service: ddService,
            version: ddVersion,
            env: ddEnv,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            silentMultipleInit: true
        });

        setDatadogLogsAndRumHandler(datadogRum);
    };

    const SegmentScript = () => {
        if (segmentTrackingId === undefined) return <></>;
        return (
            <Script id="segment" strategy="afterInteractive">
                {`
                !function(){var analytics=window.analyticsanalytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + '${segmentTrackingId}'+ "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey='${segmentTrackingId}';analytics.SNIPPET_VERSION="4.15.2";
                analytics.load('${segmentTrackingId}');
                }}();
                `}
            </Script>
        );
    };
    const TokenexScript = () => {
        return <Script id="tokenexscript" strategy="afterInteractive" src={tokenExSource} />;
    };

    const SpotlightrScript = () => {
        return (
            <Script
                id="spotlightr"
                strategy="afterInteractive"
                src="https://videos.cdn.spotlightr.com/assets/spotlightr.js"
            />
        );
    };

    return (
        <GlobalErrorBoundary>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1 user-scalable=0"
                />
            </Head>
            <SegmentScript />
            <SpotlightrScript />
            <TokenexScript />
            <SessionProvider>
                <DatadogUserManager />
                <SWRContext camelizeData={false}>
                    <PrivateRoute unProtectedRoutes={unProtectedRoutes}>
                        <StoreProvider>
                            <ThemeProvider>
                                <Component
                                    {...pageProps}
                                    updateAppStore={setAppState}
                                    appStore={appState}
                                    isMobileView={isMobileView}
                                    uiConfig={uiConfig}
                                />
                                <ModalManager />
                            </ThemeProvider>
                        </StoreProvider>
                    </PrivateRoute>
                </SWRContext>
            </SessionProvider>
        </GlobalErrorBoundary>
    );
}

export default ForaApp;

ForaApp.getInitialProps = async (context: AppContext) => {
    const pageProps = await App.getInitialProps(context);

    let isMobileView: boolean;

    isMobileView = !!(
        context.ctx.req ? context.ctx.req.headers["user-agent"] : navigator.userAgent
    )?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);

    return { ...pageProps, isMobileView };
};
