import Head from "next/head";

import { APP_PAGE_TITLE_POSTFIX } from "constants/index";

export default function ClearLayout({
    children,
    title = "",
    postfix = APP_PAGE_TITLE_POSTFIX
}: {
    children: React.ReactNode;
    title?: string;
    postfix?: string;
}) {
    return (
        <div className="min-h-screen">
            <Head>
                <title>{title ? `${title} - ${postfix}` : ""}</title>
                <link rel="icon" type="image/png" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            </Head>
            <div className="min-h-screen h-full flex flex-1">{children}</div>
        </div>
    );
}
