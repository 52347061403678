import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

import {
    CurrenciesSelectorValues,
    currencyOptionsList as currencyOptionsListLocal
} from "components/supplier-database/bookings-platform/constant";
import { CurrenciesList } from "types";
import { HttpRequests } from "utils/http-requests";
import { URL_CURRENCIES_LIST } from "constants/urls";
import { useAuth } from "hooks/use-auth";
import { ApiSelectOption, IOptionDataApi } from "api/common/ApiSelectOption";

export const useCurrencies = () => {
    const localCurrencyOption = currencyOptionsListLocal.find(
        item => item.value === CurrenciesSelectorValues.LOCAL_CURRENCY
    );
    const defaultListBeforeRequest = localCurrencyOption ? [localCurrencyOption] : [];
    const { token, isFeatureEnabled } = useAuth();
    const isDefaultCurrencyEnabled = isFeatureEnabled("enable_default_currency");
    const [currencyOptionsList, setCurrencyOptionsList] =
        useState<CurrenciesList["data"]>(defaultListBeforeRequest);

    const sessionCurrenciesList = sessionStorage.getItem("currenciesList");
    const currenciesList: CurrenciesList["data"] = sessionCurrenciesList
        ? JSON.parse(sessionCurrenciesList)
        : null;
    const sessionCurrenciesListExpireDate = sessionStorage.getItem("currenciesListExpireDate");
    const currenciesListExpireDate: number | null = sessionCurrenciesListExpireDate
        ? Number(JSON.parse(sessionCurrenciesListExpireDate))
        : null;

    const setSessionCurrenciesList = (value: CurrenciesList["data"]) =>
        sessionStorage.setItem("currenciesList", JSON.stringify(value));
    const setSessionCurrenciesListExpireDate = (value: string) =>
        sessionStorage.setItem("currenciesListExpireDate", value);

    const getCurrencies = async () => {
        try {
            const { data, response } = await HttpRequests.get({
                url: URL_CURRENCIES_LIST,
                token
            });
            if (response.ok) {
                let currencyOptionsList: CurrenciesList["data"] = data?.map(
                    (item: IOptionDataApi) => new ApiSelectOption(item)
                );
                if (localCurrencyOption && !isDefaultCurrencyEnabled) {
                    currencyOptionsList = [
                        /*added hardcoded option for local currency. We don't want add this value to the rates request*/
                        localCurrencyOption,
                        ...currencyOptionsList
                    ];
                }
                setCurrencyOptionsList(currencyOptionsList);
                setSessionCurrenciesList(currencyOptionsList);
                setSessionCurrenciesListExpireDate(
                    /*do not ask a new currencies next 1 hour */
                    JSON.stringify(DateTime.now().plus({ hours: 1 }).valueOf())
                );
            } else {
                console.log(response.statusText);
            }
        } catch (e: any) {
            console.error({ ...e });
        }
    };

    useEffect(() => {
        const isExpiredTime =
            !currenciesListExpireDate ||
            (!!currenciesListExpireDate && DateTime.now().valueOf() > currenciesListExpireDate);
        if (currenciesList?.length && !isExpiredTime && false) {
            /*TODO 'false' because caching will be implemented in next separate ticket*/
            setCurrencyOptionsList(currenciesList);

            return;
        }
        getCurrencies();
    }, []);

    const getCurrencySlug = (currency: string) => {
        return currencyOptionsList.find(item => item.value === currency)?.slug;
    };
    // if only "hotel currency" return empty array -- this will be obsolete  when we enable default currency
    return {
        currencyOptionsList:
            !isDefaultCurrencyEnabled && currencyOptionsList.length === 1
                ? []
                : currencyOptionsList,
        getCurrencySlug
    };
};
