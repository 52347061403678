import { BannerStyleTypes } from "lib/types/banners.types";
import { Megaphone, Warning, WarningCircle, X, XCircle } from "phosphor-react";
import React from "react";
import classNames from "classnames";
import { Info } from "@phosphor-icons/react";

export type BannerProps = {
    type: BannerStyleTypes;
    icon?: React.ReactNode;
    isDismissable?: boolean;
    onDismiss?: any;
    isDisabled?: boolean;
    bannerTitle?: string;
    bannerTitleClass?: string;
    content?: any;
    contentClass?: string;
    extraClass?: string;
    isJSX?: boolean;
};

const BannerStyles: {
    [key: string]: {
        containerStyle: string;
        icon: React.ReactNode;
    };
} = {
    [BannerStyleTypes.INFO]: {
        containerStyle:
            "border-l-progress border-l-[3px] bg-warning-bg rounded-r-xl p-4 md:px-5 md:py-4",
        icon: <Megaphone size={18} />
    },
    [BannerStyleTypes.ALERT]: {
        containerStyle:
            "border-l-error border-l-[3px] bg-error-bg text-error rounded-r-xl p-4 md:px-5 md:py-4",
        icon: <WarningCircle size={18} />
    },
    [BannerStyleTypes.RESTRICTED]: {
        containerStyle: "border-error bg-white border rounded-xl p-4 md:px-5 md:py-4",
        icon: <WarningCircle size={18} />
    },
    [BannerStyleTypes.BRAND_ALERT]: {
        containerStyle: "bg-mainHoliday rounded-xl p-2 md:px-3 md:py-2 mb-4",
        icon: <WarningCircle size={18} />
    },
    [BannerStyleTypes.WARNING_FLAG]: {
        containerStyle:
            "bg-orange-50 border border-orange-200 rounded-xl font-regular text-orange-900 p-2 md:px-3 md:py-2 mb-4 ",
        icon: <Warning size={16} weight="fill" className="text-orange-500" />
    },
    [BannerStyleTypes.ERROR_BODY_BORDER]: {
        containerStyle:
            "bg-red-50 border border-error rounded-xl font-regular text-orange-900 p-2 md:px-3 md:py-2 mb-4 ",
        icon: <XCircle size={16} weight="fill" className="text-error" />
    },
    [BannerStyleTypes.INFO_WARNING]: {
        containerStyle: "border-l-progress border-l-[3px] bg-warning-bg rounded-r-xl p-4",
        icon: <Warning weight="fill" size={18} className="text-progress" />
    },
    [BannerStyleTypes.INFO_BLUE]: {
        containerStyle:
            "bg-information rounded-xl p-4 text-information mb-4 border border-information",
        icon: <Info weight="fill" size={18} className="text-information" />
    }
};

const Banner = ({
    type = BannerStyleTypes.INFO,
    icon,
    isDismissable = false,
    onDismiss,
    isDisabled = false,
    content,
    contentClass = "",
    bannerTitle,
    bannerTitleClass = "",
    extraClass = "",
    isJSX = false
}: BannerProps) => {
    return (
        <div
            className={`w-full flex items-start   
        ${BannerStyles[type].containerStyle} ${extraClass}`}
            data-testid={`banner-${type}`}
        >
            <span
                className={`shrink-0 flex items-center ${bannerTitle ? "pt-[3px]" : "pt-0.5"} pr-2`}
            >
                {icon || BannerStyles[type].icon}
            </span>
            {content && (
                <div className="flex flex-col items-start justify-center min-h-[24px]">
                    {bannerTitle && (
                        <h3 className={classNames(bannerTitleClass, "font-medium")}>
                            {bannerTitle}
                        </h3>
                    )}
                    {isJSX ? (
                        <div
                            className={classNames(
                                contentClass,
                                "text-smallLH20 w-full break-normal"
                            )}
                        >
                            {content}
                        </div>
                    ) : (
                        <p
                            className="text-smallLH20 static-content-banner static-content-notes w-full"
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                    )}
                </div>
            )}
            {isDismissable && (
                <button
                    className="shrink-0 ml-auto flex w-6 h-6 items-center justify-center"
                    onClick={onDismiss}
                    disabled={isDisabled}
                >
                    <X size={18} />
                </button>
            )}
        </div>
    );
};

export default Banner;
