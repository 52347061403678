import { datadogLogs } from "@datadog/browser-logs";
import { Page } from "components/form/Page";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Navigation } from "./Navigation";
import * as Yup from "yup";
import { DisplayError, FormProperties, PageSchema, Schema } from "types/form/schema";
import { useSession } from "next-auth/react";
import { EndScreen } from "./pages/EndScreen";
import ClearLayout from "components/ClearLayout";
import Loader from "components/common/Loader";
import { sendData } from "utils/http-requests";
import { URI_REQUEST_FORM } from "constants/urls";
import { useAuth } from "hooks/use-auth";
import getConfig from "next/config";
import { useRouter } from "next/navigation";
import { getRedirectURL } from "utils/form";
import { BannerStyleTypes } from "lib/types/banners.types";

export interface DynamicFormProps {
    /** JSON file that defines the properties of the form, the components used to render and the workflow of the wizard */
    schema: Schema;
    /** Method called after the form submits */
    onDone?: () => void;
    /** Method called if the form has an error on submit */
    onError?: (errorMessage?: string) => void;
    /** Initial values for inputs in the form */
    defaultValues?: { [key: string]: number | string };
    /** Values that are not in the form but are submited to the endpoint on submit. IMPORTANT: Notice that these values override any value in the form submission if they share the same keys */
    extraParams?: { [key: string]: number | string | boolean };
    /** Object that replaces any server error result equal to apiMessage with the message and icon present on error */
    customErrors?: { apiMessage: string; error: DisplayError }[];
}
const getHumanReadable = (
    properties: FormProperties,
    values: { [key: string]: string | number | null }
) => {
    const result: { [key: string]: string | number | null } = {};

    for (const key in values) {
        if (properties[key]?.oneOf) {
            result[key] =
                properties?.[key]?.oneOf?.find(({ id }) => `${id}` === `${values[key]}`)?.name ||
                "";
        } else {
            result[key] = values[key] || "";
        }
    }

    return result;
};

const DEFAULT_ERROR_MESSAGE: DisplayError = {
    description: "There was an unexpected error, please try again",
    icon: BannerStyleTypes.ALERT
};
/** This components provides single page and wizard style forms based in a JSON Schema file. It automatically includes input validation, error handling and navigation. */
export const DynamicForm = ({
    schema,
    onDone,
    onError,
    defaultValues,
    extraParams = {},
    customErrors
}: DynamicFormProps) => {
    const router = useRouter();
    const { publicRuntimeConfig } = getConfig();
    const { token } = useAuth();
    const { data: session } = useSession();
    const [submittedFirstTime, setSubmittedFirstTime] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [state, setCurrentState] = useState<"success" | "error" | "loading" | undefined>();
    const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);
    const currentPageSchema = schema.pages[currentPage];
    useEffect(() => {
        setSubmittedFirstTime(false);
    }, [currentPage]);
    const initialValues: { [key: string]: number | string } = {};
    const validations = schema.pages.map((page: PageSchema) => {
        let validationObj: {
            [key: string]: Yup.StringSchema | Yup.NumberSchema;
        } = {};
        page?.properties?.forEach((prop: string) => {
            const item = schema.properties[prop];
            const currentType: "string" | "number" = ["string", "number"].includes(item?.type)
                ? item?.type
                : "string";
            const YupType = Yup?.[currentType]() || Yup;
            validationObj[prop] = item?.required
                ? YupType.required(`${item.name || "Field"} is required`)
                : YupType;
        });
        return Yup.object().shape(validationObj);
    });
    Object.keys(schema.properties).forEach((key: string) => {
        const currentProperty = schema.properties[key];
        if (defaultValues?.[key]) {
            initialValues[key] = defaultValues?.[key];
        } else {
            initialValues[key] =
                currentProperty?.source === "session" ? session?.userProfile?.[key] || "" : "";
        }
    });
    const currentValidationSchema = validations[currentPage];
    const reportError = (values: { [key: string]: string | number | null }, error?: string) => {
        const customError = customErrors?.find(({ apiMessage }) => apiMessage === error)?.error;
        schema?.analytics?.error(customError || DEFAULT_ERROR_MESSAGE);
        setErrorMessage(customError || DEFAULT_ERROR_MESSAGE);

        //remove sensitive data
        const { firstName, lastName, email, ...otherValues } = values;
        setCurrentState("error");
        datadogLogs.logger.error(`Error submitting user request`, otherValues);
    };

    const onSubmit = async (values: { [key: string]: string | number | null }) => {
        schema?.analytics?.send(values);
        setCurrentState("loading");
        const submitValues = schema.humanReadableSubmit
            ? getHumanReadable(schema.properties, values)
            : values;

        try {
            await sendData({
                url: schema.action
                    ? `${publicRuntimeConfig.nextBase}${schema.action}`
                    : `${URI_REQUEST_FORM}`,
                data: {
                    ...submitValues,
                    ...extraParams
                },
                ...(schema.method ? { method: schema.method } : {}),
                token
            }).then(({ response, data }) => {
                if (response && response.status >= 200 && response.status < 300) {
                    schema?.analytics?.success(data);
                    if (schema.onSuccess) {
                        schema.onSuccess(data);
                        setCurrentState(undefined);
                    } else if (schema.redirectOnSuccess) {
                        const url = getRedirectURL(schema.redirectOnSuccess, data);
                        router.push(url);
                        setCurrentState(undefined);
                    } else {
                        setCurrentState("success");
                    }
                } else if (!response || response.status >= 400) {
                    onError?.(data?.error);
                    reportError(values, data?.error);
                }
            });
        } catch (e) {
            reportError(values);
        }
    };
    if (state === "loading") {
        <ClearLayout>
            <Loader isSpin={true} />
        </ClearLayout>;
    }
    if (state === "success") {
        return (
            <EndScreen
                title="Thank you for contacting us!"
                description="We will respond to your request soon"
                onDone={onDone}
                status={state}
            />
        );
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={currentValidationSchema}
            >
                {({ handleSubmit }) => (
                    <form>
                        <section className="mt-2">
                            <Page
                                properties={schema.properties}
                                schema={currentPageSchema}
                                submittedFirstTime={submittedFirstTime}
                            />
                        </section>

                        <Navigation
                            schema={schema}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                            onSubmit={() => handleSubmit()}
                            onDone={onDone}
                            error={state === "error" ? errorMessage : undefined}
                            setSubmittedFirstTime={setSubmittedFirstTime}
                            isSubmitting={state === "loading"}
                        />
                    </form>
                )}
            </Formik>
        </>
    );
};
