import { ApiSelectOption } from "api/common/ApiSelectOption";
import * as Sentry from "@sentry/nextjs";
import { datadogLogs } from "@datadog/browser-logs";

import { getFullData, postData } from "../../utils/http-requests";
import { URL_USER_ONBOARDING } from "../../constants/urls";
import { IOnboardingStep } from "../../lib/types/onboarding.types";

export class UserProfile {
    id: number;
    uuid: string;
    email: string;
    firstName: string;
    lastName: string;
    personalEmail: string;
    timezone: ApiSelectOption;
    isSocialAccount: boolean;
    photo: string;
    filePhoto: Blob;
    username: string;
    roles: UserProfileRole[];
    registrationDate: string;
    dateJoined: string;
    country_id: number;
    country: ApiSelectOption;
    address: string;
    address_additional: string;
    city: string;
    state: string;
    postal_code: string;
    phone: string;
    twoStepVerificationEnabled: boolean;
    show_onboarding: boolean;
    entity_advisor_title: string;
    entity_name: string;
    is_entity_account: boolean;
    personal_stripe_cabinet: string;
    show_quick_links: boolean;
    is_onboarded: boolean;
    contracts: UserContract[];
    certification_type_id: number;
    crm_ids: ExternalAdvisorId[];
    referral_code: string;
    opted_into_leads: boolean;
    completed_leads_tutorial: boolean;
    hide_onboarding: boolean;
    onboarding_completed_at: string;
    default_currency: string;

    constructor(data: any) {
        this.id = data.id || 0;
        this.uuid = data.uuid || "";
        this.email = data.email || "";
        this.firstName = data.first_name || "";
        this.lastName = data.last_name || "";
        this.personalEmail = data.personal_email || "";
        this.timezone =
            (data.timezone &&
                new ApiSelectOption({
                    value: data.timezone,
                    label: data.timezone
                })) ||
            new ApiSelectOption({});
        this.isSocialAccount = data.is_social_account || false;
        this.photo = data.photo || "";
        this.username = data.username || "";
        this.filePhoto = data.file_photo || null;
        this.roles = data.roles?.map((role: any) => new UserProfileRole(role)) || [];
        this.registrationDate = data.registration_date || "";
        this.dateJoined = data.date_joined || "";
        this.country_id = data.country_id || "";
        this.country =
            (data.country && new ApiSelectOption(data.country)) || new ApiSelectOption({});
        this.address = data.address || "";
        this.address_additional = data.address_additional || "";
        this.city = data.city || "";
        this.state = data.state || "";
        this.postal_code = data.postal_code || "";
        this.phone = data.phone || "";
        this.twoStepVerificationEnabled = data.enable_2fa || false;
        this.show_onboarding = data.show_onboarding || false;
        this.entity_advisor_title = data.entity_advisor_title || "";
        this.entity_name = data.entity_name || "";
        this.is_entity_account = data.is_entity_account || false;
        this.show_quick_links = data.show_quick_links || false;
        this.personal_stripe_cabinet = data.personal_stripe_cabinet || "";
        this.contracts = data.contracts?.map((contract: any) => new UserContract(contract) || []);
        this.certification_type_id = data.certification_type_id || 0;
        this.is_onboarded = data.is_onboarded || false;
        this.crm_ids = data.crm_ids?.map((crm_id: any) => new ExternalAdvisorId(crm_id) || []);
        this.referral_code = data.referral_code || "";
        this.opted_into_leads = data.opted_into_leads || false;
        this.completed_leads_tutorial = data.completed_leads_tutorial || false;
        this.hide_onboarding = data.hide_onboarding || false;
        this.onboarding_completed_at = data.onboarding_completed_at || "";
        this.default_currency = data.default_currency || "USD";
    }
}

export class UserProfileRole {
    name: string;
    slug: string;

    constructor(data: any) {
        this.name = data.name || "";
        this.slug = data.slug || "";
    }
}

export class UserContract {
    name: string;
    status: string;
    link: string;
    date_assigned: string;
    end_date: string;
    description: string;

    constructor(data: any) {
        this.name = data.name || "";
        this.status = data.status || "";
        this.link = data.link || "";
        this.date_assigned = data.date_assigned || "";
        this.end_date = data.end_date || "";
        this.description = data.description || "";
    }
}

export const getUserOnboardingSteps = async (
    token: string
): Promise<IOnboardingStep[] | undefined> => {
    try {
        const { data, response } = await getFullData(URL_USER_ONBOARDING, token);
        return response?.ok ? data : [];
    } catch (e) {
        Sentry.captureException(e);
        datadogLogs.logger.error(`Onboarding: Failed to load advisor onboarding`, {
            error: e
        });
        console.error(e);
    }
};

export const updateUserOnboardingStep = async (stepData: IOnboardingStep, token: string) => {
    try {
        return postData(URL_USER_ONBOARDING, stepData, token);
    } catch (e) {
        Sentry.captureException(e);
        datadogLogs.logger.error(
            `Onboarding: Failed to update advisor onboarding step: (${stepData.slug})`,
            {
                error: e,
                stepData: stepData
            }
        );
        console.error(e);
    }
};

export class ExternalAdvisorId {
    advisor_to_crm_id: string;
    id: string;
    label: string;
    value: string;

    constructor(data: any) {
        this.advisor_to_crm_id = data.advisor_to_crm_id || "";
        this.id = data.id || "";
        this.label = data.label || "";
        this.value = data.value || "";
    }
}
