import { twMerge } from "tailwind-merge";
import { Label } from "components/common/v2/Label";
import { ChangeEvent, ReactNode } from "react";

type InputProps = React.ComponentPropsWithoutRef<"input">;

/** A regular (stylized) checkbox */
const CoreCheckbox = (props: InputProps) => (
    <input
        {...props}
        type="checkbox"
        className={twMerge(
            "size-[22px] rounded border-main/20 text-main focus:ring-transparent focus:!border-main cursor-pointer",
            props.className
        )}
    />
);

/** A slide-toggle checkbox */
const ToggleCheckbox = (props: InputProps) => (
    <div className="relative size-fit">
        <input
            {...props}
            type="checkbox"
            className="w-12 h-8 rounded-full border-none !bg-none bg-tertiaryOld text-main cursor-pointer focus:ring-transparent focus-visible:ring-blue-600"
        />
        <div
            className={twMerge(
                "absolute top-0 size-8 rounded-full grid place-content-center bg-white border-2 transition-transform duration-100",
                !props.checked ? "border-tertiaryOld" : "translate-x-1/2 border-main"
            )}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
            >
                <path
                    className={props.checked ? "fill-main" : undefined}
                    d="M13.2806 1.53032L5.2806 9.53032C5.21092 9.60024 5.12813 9.65572 5.03696 9.69358C4.9458 9.73143 4.84806 9.75092 4.74935 9.75092C4.65064 9.75092 4.5529 9.73143 4.46173 9.69358C4.37057 9.65572 4.28778 9.60024 4.2181 9.53032L0.718098 6.03032C0.648333 5.96056 0.592993 5.87774 0.555236 5.78658C0.51748 5.69543 0.498047 5.59774 0.498047 5.49907C0.498047 5.40041 0.51748 5.30272 0.555236 5.21156C0.592993 5.12041 0.648333 5.03759 0.718098 4.96782C0.787863 4.89806 0.870685 4.84272 0.961837 4.80496C1.05299 4.76721 1.15069 4.74777 1.24935 4.74777C1.34801 4.74777 1.44571 4.76721 1.53686 4.80496C1.62801 4.84272 1.71083 4.89806 1.7806 4.96782L4.74997 7.9372L12.2193 0.469074C12.3602 0.328178 12.5513 0.249023 12.7506 0.249023C12.9499 0.249023 13.141 0.328178 13.2818 0.469074C13.4227 0.609971 13.5019 0.801067 13.5019 1.00032C13.5019 1.19958 13.4227 1.39068 13.2818 1.53157L13.2806 1.53032Z"
                />
            </svg>
        </div>
    </div>
);

export type CheckboxVariant = "checkbox" | "toggle";

const variantMap: Record<CheckboxVariant, (props: InputProps) => JSX.Element> = {
    checkbox: CoreCheckbox,
    toggle: ToggleCheckbox
};

export interface CheckboxProps extends InputProps {
    /** The type of checkbox to render */
    variant?: CheckboxVariant;
    /** An optional label, rendered to the right of the checkbox */
    label?: ReactNode;
    /** A callback for when the checkbox is clicked */
    onChecked?: (isChecked: boolean) => void;
    /** If true, displays an asterisk (*) next to the label */
    required?: boolean;
}

/** A checkbox with an optional label */
export const Checkbox = ({
    variant = "checkbox",
    label,
    required = false,
    onChecked,
    onChange,
    ...otherProps
}: CheckboxProps) => {
    const Box = variantMap[variant];

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        onChecked?.(!otherProps.checked);
    };

    return label ? (
        <Label
            required={required}
            className="flex gap-x-2 items-center md:mb-0 pb-1 text-medium font-normal text-main cursor-pointer select-none"
        >
            <Box {...otherProps} onChange={onChangeHandler} />
            {label}
        </Label>
    ) : (
        <Box {...otherProps} onChange={onChangeHandler} />
    );
};
