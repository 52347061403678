import { InputError } from "../InputError";
import { Label } from "../Label";
import { SingleSelectContextT } from "./types";

export const SelectInputContainer: React.FC<
    Pick<SingleSelectContextT, "id" | "required" | "label" | "error" | "showLabelOnDesktop"> & {
        className?: string;
    }
> = ({ className, id, required, label, error, children, showLabelOnDesktop }) => {
    return (
        <div className={className}>
            {showLabelOnDesktop && (
                <Label htmlFor={id} required={required} data-testid="select-input-label">
                    {label}
                </Label>
            )}
            {children}
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
};
