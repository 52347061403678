import { Combobox } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { useMemo } from "react";
import { SelectInput } from "./SelectInput";
import { MultiSelectOption } from "./types";
import { SelectOptions } from "./SelectOptions";
import { useMultiSelectContext } from "./select-context";

export const isSelected = (option: MultiSelectOption, values: string[]): boolean => {
    return option.options
        ? option.options.every(i => isSelected(i, values))
        : values.includes(option.value);
};

export const getValues = (option: MultiSelectOption): string[] =>
    !option.options
        ? [option.value]
        : option.options?.reduce((values: string[], sub) => {
              values.push(...getValues(sub));
              return values;
          }, []);

const formatQuery = (query: string) => query.toLowerCase().split(" ").join("");

export const filterByName = (name: string, query: string) => {
    return formatQuery(name).includes(formatQuery(query));
};
export const filterOptions = (option: MultiSelectOption, query: string): boolean => {
    return (
        filterByName(option.name, query) ||
        !!option.options?.some((i: MultiSelectOption) => filterOptions(i, query))
    );
};

const deepFilter = (options: MultiSelectOption[], query: string) => {
    return options
        .map(option => {
            if (option.options) {
                const found = option.options.filter(option => filterByName(option.name, query));
                return {
                    ...option,
                    options: found.length ? found : option.options
                };
            }

            return filterByName(option.name, query) ? option : { value: "", name: "" };
        })
        .filter(v => !!v.value);
};

export const getFilteredOptions = (options: MultiSelectOption[], query: string) => {
    const filtered = options.filter((option: MultiSelectOption) => filterOptions(option, query));

    return deepFilter(filtered, query);
};

export function MultiSelectDropdown({ isMobile = false }: { isMobile?: boolean }) {
    const { values, onChange, options, showClearButton, query, onSearch, onClear } =
        useMultiSelectContext();

    const filteredOptions = useMemo(() => {
        if (query === "") {
            return options;
        }

        return getFilteredOptions(options, query);
    }, [query]);

    const handleToggleSelectGroup = (option: MultiSelectOption) => {
        const group = options.find((group: MultiSelectOption) => group.name === option.name);
        const selectedGroup = group ? getValues(group) : [];

        if (isSelected(option, values)) {
            const updatedSelected = values.filter((val: string) => !selectedGroup.includes(val));
            onChange([...updatedSelected]);
            return;
        }

        onChange([...values, ...selectedGroup]);
    };

    const clearAll = () => {
        onChange([]);
        onClear?.();
    };

    const optionsLength = useMemo(() => {
        if (isMobile) {
            const nestedOptions = options.reduce((acc, option) => {
                if (option.options) {
                    return acc + option.options.length;
                }
                return acc + 1;
            }, 0);

            return nestedOptions + options.length;
        }
        return 0;
    }, [isMobile]);
    // 40px is the height of each option, input is 16px padding top + bottom
    const dynamicHeight = optionsLength * 40 + 16;

    return (
        <Combobox
            value={values}
            multiple
            immediate
            data-testid="multi-select"
            onChange={val => {
                /**
                 * if clear is clicked empty string will be last in array of values returned here.
                 * check last item in case someone has an empty string as a value, we dont want to clear all values */
                if (val[val.length - 1] === "") {
                    onChange([]);
                    return;
                }
                onChange(val);
            }}
            onClose={() => onSearch("")}
        >
            {({ open }) => (
                <div
                    className={twMerge(
                        "relative",
                        isMobile &&
                            `min-h-[${
                                // 65 is height of footer + input
                                dynamicHeight + 65 + 40
                            }px]`,
                        optionsLength > 7 &&
                            `min-h-[${
                                // 65 is height of footer, 56 is height of input + padding
                                dynamicHeight + 65 + 56
                            }px] h-auto max-h-[90svh]`
                    )}
                >
                    <SelectInput open={open} isMobile={isMobile} mode="multi" />

                    <SelectOptions
                        options={filteredOptions}
                        clearSelection={clearAll}
                        isGroupSelected={option => isSelected(option, values)}
                        onGroupSelect={handleToggleSelectGroup}
                        isMobile={isMobile}
                        showClearButton={!isMobile && showClearButton}
                        isSingleSelect={false}
                        optionsHeight={dynamicHeight}
                    />
                </div>
            )}
        </Combobox>
    );
}
