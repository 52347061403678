import React, { HTMLAttributeAnchorTarget, ReactNode, forwardRef } from "react";
import { Icon as PhosphorIconT, IconProps } from "phosphor-react";
import { ClassNameValue, twMerge } from "tailwind-merge";
import Link from "next/link";

import { TooltipWrapper } from "../TooltipWrapper";

export type ButtonVariantT = "primary" | "secondary" | "text" | "link";
export type ButtonColorT = "default" | "error" | "link";
export type ButtonSizeT = "small" | "medium";
export type ButtonTooltipModeT = "hover" | "click";

const defaultButtonVariant: ButtonVariantT = "primary";
const defaultButtonColor: ButtonColorT = "default";
const defaultButtonSize: ButtonSizeT = "medium";

const focusOutline =
    "focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2 focus-visible:!outline-link";

export const baseButtonStyles = `inline-flex cursor-pointer items-center justify-center rounded-xl whitespace-nowrap disabled:text-[#C8C8C8] font-medium gap-2 ${focusOutline}`;

export interface ButtonProps extends Omit<React.ComponentProps<"button">, "ref"> {
    /** Button sizing (default: medium) */
    size?: ButtonSizeT;
    /** The button style (default: primary) */
    variant?: ButtonVariantT;
    /** Button coloring (default: default) */
    color?: ButtonColorT;
    /** An icon to display to the left side of the button's contents */
    LeftIcon?: PhosphorIconT;
    /** An icon to display to the right side of the button's contents */
    RightIcon?: PhosphorIconT;
    /** Any props to apply to the icon passed in */
    iconProps?: Omit<IconProps, "size">;
    /** If specified, creates a tooltip to show when the button is hovered or clicked */
    tooltip?: ReactNode;
    /** Specifies whether to show the tooltip on button hover or click (default: hover) */
    tooltipMode?: ButtonTooltipModeT;
    /** If true, shrinks the button down to just its icon when smaller than tablet size (only works with icon specified) */
    shrinkOnMobile?: boolean;
    /** If true, the button will be disabled */
    disabled?: boolean;
    /** If specified, the button will be rendered as a Link tag with the specified href */
    href?: string;
    /** Specifies where to open the anchor tag */
    target?: HTMLAttributeAnchorTarget;
}

export const getButtonSizeStyle = (
    size: ButtonSizeT,
    isIcon = false,
    shrinkOnMobile = false,
    variant: ButtonVariantT = defaultButtonVariant
): string => {
    switch (size) {
        case "small":
            return twMerge(
                variant === "link" ? "text-sm" : "py-1.5 text-sm h-8 leading-[8px]",
                (isIcon || shrinkOnMobile) && "min-w-8",
                !isIcon &&
                    (shrinkOnMobile ? "tablet:w-fit tablet:px-3" : variant !== "link" && "px-3")
            );
        case "medium":
            return twMerge(
                variant === "link" ? "text-medium" : "text-medium h-12 leading-[48px]",
                (isIcon || shrinkOnMobile) && "min-w-12",
                !isIcon &&
                    (shrinkOnMobile
                        ? "tablet:w-fit tablet:px-[15px]"
                        : variant !== "link" && "px-[15px]")
            );
    }
};

export const getButtonVariantStyle = (variant: ButtonVariantT, color?: ButtonColorT): string => {
    switch (variant) {
        case "primary":
            return twMerge(
                "text-white bg-main enabled:hover:bg-[#2c2c2c] disabled:text-tertiaryOld disabled:bg-[#1414141f]",
                color === "error" && "bg-error enabled:hover:bg-error"
            );
        case "secondary":
            return twMerge(
                "text-main bg-[#1414140f] enabled:hover:bg-[#14141414] disabled:text-disabled disabled:bg-[#1414140a]",
                color === "error" && "bg-error"
            );
        case "text":
            return twMerge(
                "text-main enabled:hover:bg-[#1414140f] disabled:text-disabled",
                color === "error" && "text-error enabled:hover:bg-lightRedBg",
                color === "link" && "text-link"
            );
        case "link":
            return twMerge(
                "text-primary hover:text-primary-hover disabled:text-disabled underline",
                color === "error" && "text-error",
                color === "link" && "text-link"
            );
    }
};

/** Generates complete button styling based on props */
export const getButtonStyles = (
    {
        size = defaultButtonSize,
        variant = defaultButtonVariant,
        color = defaultButtonColor,
        isIcon,
        shrinkOnMobile
    }: {
        size?: ButtonSizeT;
        variant?: ButtonVariantT;
        color?: ButtonColorT;
        isIcon?: boolean;
        shrinkOnMobile?: boolean;
    } = {},
    ...classNames: ClassNameValue[]
): string =>
    twMerge(
        baseButtonStyles,
        getButtonSizeStyle(size, isIcon, shrinkOnMobile, variant),
        getButtonVariantStyle(variant, color),
        ...classNames
    );

export const Button = forwardRef(function Button(
    {
        children,
        type = "button",
        size = defaultButtonSize,
        variant = defaultButtonVariant,
        color = defaultButtonColor,
        LeftIcon,
        RightIcon,
        iconProps,
        tooltip,
        tooltipMode,
        shrinkOnMobile,
        disabled,
        href,
        target,
        ...otherProps
    }: ButtonProps,
    ref?: React.Ref<HTMLButtonElement | HTMLAnchorElement>
) {
    const hasLabel = !!children;
    const collapseToIcon = shrinkOnMobile && !!LeftIcon;
    const buttonStyles = getButtonStyles(
        {
            size,
            variant,
            color,
            isIcon: !hasLabel,
            shrinkOnMobile: collapseToIcon
        },
        otherProps.className
    );

    const iconWithChildren = (
        <>
            {LeftIcon && <LeftIcon size={20} {...iconProps} />}
            {!collapseToIcon ? children : <span className="hidden tablet:inline">{children}</span>}
            {RightIcon && <RightIcon size={20} {...iconProps} />}
        </>
    );

    const btn =
        !href || disabled ? (
            <button
                ref={ref as React.Ref<HTMLButtonElement>}
                {...otherProps}
                type={type}
                disabled={disabled}
                className={buttonStyles}
            >
                {iconWithChildren}
            </button>
        ) : (
            <Link
                ref={ref as React.Ref<HTMLAnchorElement>}
                {...(otherProps as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
                className={buttonStyles}
                href={href}
                target={target}
                prefetch={false}
            >
                {iconWithChildren}
            </Link>
        );

    return !tooltip ? (
        btn
    ) : (
        <TooltipWrapper content={tooltip} openOnClick={tooltipMode === "click"}>
            {btn}
        </TooltipWrapper>
    );
});
