import { useState, useEffect } from "react";
import { WINDOW_SIZES } from "../constants/window-sizes";

const MOBILE_THRESHOLD = WINDOW_SIZES.tablet;

export type WindowDimensions = {
    width: number;
    height: number;
    isMobile: boolean;
};

function getWindowDimensions(): WindowDimensions {
    if (typeof window === "undefined") {
        return { width: 0, height: 0, isMobile: false };
    }
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height, isMobile: width < MOBILE_THRESHOLD };
}

/** Returns the current window dimensions and a flag indicating if we are on a mobile-size screen.
 *  Creates a window resize listener for live updates.
 */
export const useWindowDimensions = (): WindowDimensions => {
    const [windowDimensions, setWindowDimensions] = useState(() => getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};

// TODO: remove default export and use named export
export default useWindowDimensions;
