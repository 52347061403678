import { URL_COMMISSIONS_V2_SUMMARIES } from "constants/urls";
import { sendData } from "utils/http-requests";

export const getNumberOfBookingsForUser = async (token: string): Promise<number | undefined> => {
    const { data, response } = await sendData({
        url: `${URL_COMMISSIONS_V2_SUMMARIES}`,
        token
    });
    return data?.commissions;
};
