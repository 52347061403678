import { SVGProps } from "react";

/** The Fora brand logo (stylized "FORA") */
export const ForaLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2388 732" {...props}>
        <path
            id="Fora_Wordmark"
            data-name="Fora Wordmark"
            d="M1917,460l125-345,141,345h-266ZM1452,348h-85V46h105c104,0,158,54,158,147,0,101-64,155-178,155ZM1782,716h60c-10-16-7-30,13-85l51-141h289l59,143c23,56,26,67,16,83h118c-16-24-24-43-37-75L2094,16h-89c16,40,15,73,1,110l-185,497c-22,56-32,74-60,74-38,0-61-34-106-145l-19-47c-34-83-73-123-137-134,136-5,221-68,221-180,0-110-83-175-229-175h-230c14,22,18,54,18,90v520c0,36-4,68-18,90h126c-16-22-20-54-20-90v-248h43c63,0,90,17,121,96l40,103c43,110,115,139,211,139ZM807,702c-163,0-270-133-270-336S644,30,807,30c164,0,272,133,272,336s-108,336-272,336ZM807,732c207,0,364-157,364-366S1014,0,807,0c-206,0-362,157-362,366s156,366,362,366ZM0,716h126c-16-22-20-54-20-90v-248h124c47,0,88,4,117,20v-70c-29,16-70,20-117,20h-124V46h149c59,0,100,11,148,39l-15-69H0c14,22,18,54,18,90v520c0,36-4,68-18,90Z"
        />
    </svg>
);
