// Validates that the url starts with patterns like http:// https:// ftp:// // www. or has a pattern like aaa.com
const shouldURLOpenInNewTabRegExp = new RegExp("^([a-z]+.[a-z]+)|(www.)|(?:[a-z+]+:)?//", "i");
export const shouldURLOpenInNewTab = (url: string | null = ""): boolean => {
    if (!url) return false;
    return shouldURLOpenInNewTabRegExp.test(url);
};

export const isValidAbsoluteURL = (url: string | null = ""): boolean => {
    if (!url) return false;
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
};

export const getURL = (pathname: string, searchParams: URLSearchParams): string => {
    const stringParams = searchParams.toString();
    return `${pathname}${stringParams ? `?${stringParams}` : ""}`;
};
