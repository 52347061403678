export interface IOptionDataApi {
    /*interface is used to describe expected API response */
    id?: string;
    value?: string;
    text?: string;
    label?: string;
    slug?: string;
}
export class ApiSelectOption {
    value: string;
    label: string;
    slug?: string;
    rawData?: any; //could be used on custom dropdown with SelectAsync components

    constructor(data: any) {
        // TODO wait to refactoring all defaultValue={new ApiSelectOption(values.country)} to use this -> data: IOptionDataApi
        this.value = data?.id || data?.value || "";
        this.label = data?.text || data?.label || data?.value || "";
        this.slug = data?.slug || "";
        this.rawData = data || undefined;
    }
}
