import { twMerge } from "tailwind-merge";

export const LoadingIndicator = ({ className }: { className?: string }): JSX.Element => {
    return (
        <div
            data-testid="loading-indicator"
            className={twMerge(
                "h-10 w-[58px] py-1 bg-white rounded-sm shadow-mapPin flex items-center justify-center",
                className
            )}
        >
            <div
                data-testid="loading-dots"
                className="flex justify-center my-0 mx-auto loading-dots-dark"
            />
        </div>
    );
};
