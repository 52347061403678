import { Icon } from "phosphor-react";
import { ComponentType, ReactNode, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { TooltipWrapper } from "./TooltipWrapper";

type IconButtonVariant = "primary" | "secondary" | "text" | "light";
type IconButtonSize = "small" | "large";
type IconButtonShape = "circle" | "square";

export type IconButtonProps = Omit<React.ComponentProps<"button">, "ref"> & {
    onClick?: (arg: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    /** default "primary" */
    variant?: IconButtonVariant;
    isIconFilled?: boolean;
    className?: string;
    /** default "large" */
    size?: IconButtonSize;
    /** default "circle" */
    shape?: IconButtonShape;
    /** change the icon color from it's default - use "text-${colorClass}". Only use this if necessary, e.g. favorites list red hearts */
    iconColorClassName?: string;
    testId?: string;
    tooltip?: ReactNode;
    tooltipMode?: "hover" | "click";
} & ({ Icon: Icon; CustomIcon?: never } | { Icon?: never; CustomIcon: ComponentType<any> });

const variantStyle: Record<IconButtonVariant, string> = {
    light: `shadow-md shadow-black/10 border-solid border-[1px] border-lightGreyBorder text-main bg-white/80 
        hover:shadow-black/20 hover:bg-white disabled:text-disabled disabled:hover:shadow-black/10`,
    primary:
        "text-white bg-main hover:bg-main/90 disabled:bg-main/[.12] disabled:text-tertiaryOld disabled:hover:bg-main/[0px]",
    secondary: "text-main bg-main/[.06] hover:bg-main/[.08] disabled:text-disabled",
    text: "text-main bg-transparant hover:bg-main/[.06] disabled:text-disabled disabled:hover:bg-main/[0] disabled:bg-transparant"
};

const iconButtonSize: Record<IconButtonSize, string> = {
    small: "h-8 min-w-8",
    large: "h-12 min-w-12"
};
const iconButtonShape: Record<IconButtonShape, string> = {
    circle: "rounded-full",
    square: "rounded-xl"
};
// currently we dont have diff variants or sizes for the icon button but there may be in the future
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
    {
        Icon,
        CustomIcon,
        onClick,
        className,
        isIconFilled = false,
        variant = "primary",
        disabled = false,
        type = "button",
        size = "large",
        shape = "circle",
        iconColorClassName = "",
        testId,
        tooltip,
        tooltipMode
    },
    ref
) {
    const iconSize = size === "small" ? 18 : 20;
    const customIconSize = size === "small" ? "size-[18px]" : "size-5";

    const btn = (
        <button
            data-testid={testId ? testId : "icon-button"}
            type={type}
            ref={ref}
            disabled={disabled}
            className={twMerge(
                className,
                `justify-center flex items-center disabled:cursor-default focus-visible:outline-selected focus-visible:outline focus-visible:outline-1`,
                variantStyle[variant],
                iconButtonSize[size],
                iconButtonShape[shape]
            )}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onClick?.(e);
            }}
        >
            {CustomIcon && (
                <CustomIcon className={`${customIconSize} ${disabled ? "" : iconColorClassName}`} />
            )}
            {Icon && (
                <Icon
                    size={iconSize}
                    className={disabled ? "" : iconColorClassName}
                    weight={isIconFilled ? "fill" : "regular"}
                />
            )}
        </button>
    );

    return !tooltip ? (
        btn
    ) : (
        <TooltipWrapper
            delayShow={500}
            content={tooltip}
            variant="small"
            openOnClick={tooltipMode === "click"}
        >
            {btn}
        </TooltipWrapper>
    );
});
