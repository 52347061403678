import { twMerge } from "tailwind-merge";
import { Label } from "components/common/v2/Label";
import { InputError } from "./InputError";

interface Props extends Omit<React.ComponentPropsWithoutRef<"textarea">, "onChange"> {
    /** A label for the text area */
    label?: string;
    /** An error message to display in red below the text area */
    error?: string;
    onChange: (value?: string | number) => void;
    /** If true, adds a * to the right of the label text */
    required?: boolean;
}

/** A large input for multiple lines of text */
export const Textarea = ({ onChange, label, error, className, required, ...otherProps }: Props) => (
    <div>
        <Label htmlFor={otherProps.id} required={required}>
            {label}
        </Label>
        <textarea
            {...otherProps}
            className={twMerge(
                "form-control text-medium py-[11px] min-h-[167px] resize-none placeholder:text-main/50 rounded-xl",
                error && "border-error",
                className
            )}
            onChange={e => {
                onChange(e.target.value);
            }}
        />
        <InputError className="mt-2">{error}</InputError>
    </div>
);
