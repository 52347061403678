import { Button } from "../button/Button";
import { twMerge } from "tailwind-merge";

export const SingleSelectActionButton = ({
    isMobile,
    onClick,
    label,
    className
}: {
    isMobile: boolean;
    onClick: () => void;
    label: string;
    className?: string;
}) => {
    return (
        <div
            className={twMerge(
                `border-primary border-t pt-4 pl-4 bg-white -mx-4 w-auto`,
                isMobile && "-mx-6",
                className
            )}
        >
            <Button
                aria-label={label}
                data-testid="action-button"
                variant="text"
                className="underline font-normal"
                onClick={onClick}
            >
                {label}
            </Button>
        </div>
    );
};
