import useWindowDimensions from "hooks/useWindowDimensions";
import { MultiSelectMobile } from "./MultiSelectMobile";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import { MultiSelectContextProvider } from "./select-context";
import { MultiSelectProps } from "./types";

export function MultiSelect({ showSelected = true, ...props }: MultiSelectProps) {
    const { isMobile } = useWindowDimensions();
    return (
        <MultiSelectContextProvider {...props} showSelected={showSelected}>
            {isMobile ? <MultiSelectMobile /> : <MultiSelectDropdown />}
        </MultiSelectContextProvider>
    );
}
