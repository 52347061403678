import { ComboboxInput } from "@headlessui/react";
import { CaretDown, MagnifyingGlass } from "@phosphor-icons/react";
import { twMerge } from "tailwind-merge";
import { useMultiSelectContext, useSingleSelectContext } from "./select-context";
import { selectInputBaseStyles } from "./const";
import { SelectInputContainer } from "./SelectInputContainer";

export const SelectInput = ({
    open,
    mode = "single",
    onClick,
    readOnly = false,
    isMobile = false
}: {
    open: boolean;
    readOnly?: boolean;
    onClick?: () => void;
    isMobile?: boolean;
    mode?: "single" | "multi";
}) => {
    const context = mode === "multi" ? useMultiSelectContext : useSingleSelectContext;

    const placeholder = context().placeholder;
    const displayValue = context().displayValue;
    const onSearch = context().onSearch;
    const contextProps = context();
    const disabled = contextProps.disabled;

    return (
        <SelectInputContainer {...contextProps} className={contextProps.containerClassName}>
            <div className={twMerge("w-full relative")}>
                <ComboboxInput
                    data-testid={`select-input${
                        placeholder
                            ? `-${placeholder.toLocaleLowerCase().split(" ").join("-")}`
                            : ""
                    }`}
                    onClick={onClick}
                    enterKeyHint="search"
                    autoFocus={isMobile}
                    disabled={disabled}
                    className={twMerge(selectInputBaseStyles, "text-ellipsis pr-6")}
                    onChange={event => onSearch(event.target.value)}
                    placeholder={placeholder || "Search"}
                    readOnly={readOnly}
                    type="search"
                    autoComplete="off"
                    displayValue={() => {
                        return displayValue ?? "";
                    }}
                />

                <span className="absolute right-4 top-1/2 ml-6 pointer-events-none">
                    {!isMobile ? (
                        <CaretDown
                            size={15}
                            className={twMerge(
                                "transform -translate-y-1/2",
                                open && "rotate-180",
                                disabled && "text-tertiary"
                            )}
                        />
                    ) : (
                        <MagnifyingGlass size={15} className="transform -translate-y-1/2" />
                    )}
                </span>
            </div>
        </SelectInputContainer>
    );
};
