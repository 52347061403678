import { useEffect, useRef, useState } from "react";
import { SessionContextValue, signIn, signOut, useSession } from "next-auth/react";
import * as Sentry from "@sentry/browser";

import { UserProfile } from "api/user/profile";
import { User } from "pages/api/user";
import { URI_HOME, URI_LOGIN, URL_BASE } from "constants/urls";
import { getEncodedURlErrorMessage } from "utils/form/form-utils";
import { getStaticGoogleLink } from "utils/google-links";

import { getData } from "utils/http-requests";
import { AccessibleFeatures } from "../types";
import { AuthProviders } from "../constants";
import { checkFeatureEnabled } from "../utils/feature-flags";

export const BLANK_USER = new UserProfile({});

type UseAuthReturn = {
    user: User;
    userProfile: UserProfile;
    isImpersonating: boolean;
    handleChangeUserProfile: (response?: UserProfile) => Promise<void>;
    handleFirstLoginUser: (response: string, callbackDestination?: string) => Promise<void>;
    getUserGoogleLink: (urls: string[] | string) => string;
    token: string;
    accessibleFeatures: AccessibleFeatures;
    permissions: Record<string, boolean>;
    isFeatureEnabled: (featureKey: string, featureValue?: string) => boolean;
    status: SessionContextValue["status"];
    refresh: () => Promise<void>;
};

export const useAuth = (): UseAuthReturn => {
    const [user, setUser] = useState({
        isLoggedIn: false,
        login: "",
        roles: []
    } as User);
    const { data: session, status, update: updateSession } = useSession();

    useEffect(() => {
        if (session?.user && session?.userProfile) {
            const sessionUser = {
                // @ts-ignore
                roles: session?.userProfile?.roles,
                login: session?.user.name || "",
                isLoggedIn: true
            };
            // handlePortalIdentify();
            setUser(sessionUser);
        }
        Sentry.setUser({
            username: session?.user?.name || "unknown",
            email: session?.userProfile?.email
        });
    }, [session?.user, session?.userProfileError, session?.userProfile]);

    const handleChangeUserProfile = async (response?: UserProfile) => {
        if (response) {
            // @ts-ignore
            session.userProfile = response;
            return;
        }
        if (session?.accessToken) {
            try {
                const userProfileData = await getData(
                    `${URL_BASE}profile/`,
                    // @ts-ignore
                    session?.accessToken
                );
                // @ts-ignore
                session.userProfile = new UserProfile(userProfileData);
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleFirstLoginUser = async (response: string, callbackDestination = URI_HOME) => {
        try {
            await signIn(AuthProviders.FIRST_TIME_LOGIN, {
                accessToken: response,
                redirect: true,
                callbackUrl: callbackDestination
            });
        } catch (e) {
            console.error(e);
        }
    };

    const getUserGoogleLink = (urls: string[] | string): string => {
        const userProfile = session?.userProfile;

        if (typeof urls === "string") {
            let parsedUrl = urls;

            try {
                const searchParams = new URL(parsedUrl);

                const queryDivider = [...searchParams.searchParams.keys()].length ? "&" : "?";

                if (
                    ["docs.google.com", "drive.google.com"].some(pattern => urls.includes(pattern))
                ) {
                    parsedUrl = `${parsedUrl}${queryDivider}authuser=${userProfile.email}`;
                }
            } catch (e) {
                // @ts-ignore
                console.log(`${e.name}: ${e.message}:`, parsedUrl);
                console.log("Make sure the link includes https://");
                return "#";
            }

            return parsedUrl;
        }

        try {
            return `${getStaticGoogleLink(urls)}${userProfile.email}`;
        } catch (e) {
            console.error(e);
            return "#";
        }
    };

    const isFeatureEnabled = (featureKey: string, featureValue: string = ""): boolean =>
        checkFeatureEnabled(session?.accessibleFeatures ?? {}, featureKey, featureValue);

    return {
        user,
        // If the user's session expires, returning a user object with blank data will
        // prevent FE crashes until an API call is made and the user is forced to log out
        userProfile: (session?.userProfile as UserProfile) ?? BLANK_USER,
        isImpersonating: !!session?.isImpersonating,
        permissions: session?.permissions ?? {},
        handleChangeUserProfile,
        handleFirstLoginUser,
        getUserGoogleLink,
        token: (session?.accessToken as string) ?? "",
        accessibleFeatures: (session?.accessibleFeatures as AccessibleFeatures) ?? {},
        isFeatureEnabled,
        status,
        refresh: async () => {
            await updateSession();
        }
    };
};
