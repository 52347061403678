import { useEffect } from "react";
import getConfig from "next/config";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { useAuth } from "../../hooks/use-auth";
import PortalAnalytics from "PortalAnalytics";

const { publicRuntimeConfig } = getConfig();
const enableDataDogRUM = publicRuntimeConfig.enableDataDogRUM === "true";

export default function DatadogUserManager() {
    const { userProfile } = useAuth();

    const profileId = userProfile?.id.toString() as string;
    const email = userProfile?.email;
    const firstName = userProfile?.firstName;
    const lastName = userProfile?.lastName;
    const referralCode = userProfile?.referral_code;
    const handlePortalIdentify = () => {
        if (profileId) {
            PortalAnalytics.identify(profileId, {
                email,
                first_name: firstName,
                last_name: lastName
            });
        }
    };

    useEffect(() => {
        if (userProfile) {
            if (enableDataDogRUM) {
                datadogRum.setUser({
                    id: userProfile.id?.toString(),
                    name: `${userProfile.firstName} ${userProfile.lastName}`,
                    email: userProfile.email
                });
            }

            datadogLogs.setUser({
                id: userProfile.id?.toString(),
                name: `${userProfile.firstName} ${userProfile.lastName}`,
                email: userProfile.email
            });

            //TODO: Setup context for this instead, or use session context
            handlePortalIdentify();
        }
    }, [userProfile]);

    return null;
}
