import confetti from "canvas-confetti";

/** "Realistic Look" confetti animation taken from: https://www.kirilv.com/canvas-confetti/ */
const fireTheConfetti = () => {
    const count = 200;
    const defaults = {
        origin: { y: 0.7 }
    };

    function fire(particleRatio: number, opts: confetti.Options | undefined) {
        confetti({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio)
        });
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55
    });
    fire(0.2, {
        spread: 60
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45
    });
};

const randomInRange = (min: number, max: number): number => Math.random() * (max - min) + min;

const fireworkDefaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 60
};

/** "Fireworks" confetti animation taken from: https://www.kirilv.com/canvas-confetti/ */
export const launchFireworks = (seconds: number = 15): number => {
    const duration = seconds * 1000;
    const animationEnd = Date.now() + duration;

    const interval: number = window.setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        // Since particles fall down, start a bit higher than random
        confetti({
            ...fireworkDefaults,
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        });
        confetti({
            ...fireworkDefaults,
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        });
    }, 250);

    return interval;
};

const starburstDefaults = {
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 3,
    colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"]
};

export const starburst = (options: confetti.Options = {}): void => {
    confetti({
        ...starburstDefaults,
        particleCount: 10,
        scalar: 0.5,
        shapes: ["star"],
        ...options
    });
};

export default fireTheConfetti;
