import { Label } from "../Label";
import { Modal } from "../modal/Modal";
import { SelectMobileProps } from "./types";

export const SelectMobile: React.FC<SelectMobileProps> = ({
    footerButtons,
    selectInput,
    closeModal,
    children,
    showModal,
    label,
    required
}) => {
    return (
        <>
            {selectInput}

            <Modal
                data-testid={`select-mobile-modal-${label}`}
                open={showModal}
                title={
                    <Label required={required} className="mb-0">
                        {label}
                    </Label>
                }
                onClose={closeModal}
                footerButtons={footerButtons}
            >
                {children}
            </Modal>
        </>
    );
};
