import React from "react";

export default function FooterMinimal() {
    return (
        <footer>
            <p className="text-center text-medium sm:text-small text-secondaryDark/50 px-2">
                &copy; {new Date().getFullYear()} Fora Travel, Inc. All Rights Reserved.
            </p>
        </footer>
    );
}
