import React from "react";
import { useSearchParams } from "next/navigation";

import { useModalManager } from "hooks/use-modal-manager";
import { CertificationQuizFailure } from "components/training/certification-quiz-result/CertificationQuizFailure";
import { CertificationQuizSuccess } from "components/training/certification-quiz-result/CertificationQuizSuccess";

export const CertificationQuizResultModal = () => {
    const { closeModal } = useModalManager();
    const certificationQuizResult = useSearchParams()?.get("certification_quiz_result");
    const success = certificationQuizResult === "success";

    if (!success) return <CertificationQuizFailure closeModal={closeModal} />;
    return <CertificationQuizSuccess closeModal={closeModal} />;
};
