import React, { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { ModalFooterButtons, ModalProps, ModalSize } from "./modal-types";
import { ModalHeader } from "./ModalHeader";
import { ModalFooter } from "./ModalFooter";
import { DRAWER_EASE_IN, DRAWER_EASE_OUT } from "../Drawer";

export type { ModalSize, ModalFooterButtons, ModalProps };

const modalSizeStyles: Record<ModalSize, string> = {
    // Desktop: Fixed width (376px); Mobile: Drawer on bottom
    small: "max-h-[90svh] tablet:w-[376px] left-0 right-0 bottom-0 rounded-t-xl",
    // Desktop: Fixed width (472px); Mobile: Drawer on bottom
    medium: "max-h-[90svh] tablet:w-[472px] left-0 right-0 bottom-0 rounded-t-xl",
    // Desktop: Fixed width (568px); Mobile: Fill screen
    large: "tablet:max-h-[90svh] tablet:w-[568px] inset-0",
    // Desktop: Fixed width/height (90% screen); Mobile: Fill screen
    xl: "tablet:h-[90svh] tablet:w-[90vw] inset-0"
};

export const Modal = ({
    size = "medium",
    footerButtons,
    className,
    overlayClassName,
    children,
    open = false,
    noPadding,
    ...headerProps
}: ModalProps) => {
    const modalContents = (
        <DialogPanel
            as="div"
            className={twMerge(
                "fixed tablet:relative flex flex-col shadow bg-white m-auto tablet:rounded-xl",
                modalSizeStyles[size],
                className
            )}
            onClick={e => e.stopPropagation()}
        >
            <ModalHeader {...headerProps} />
            <div
                className={twMerge(
                    noPadding ? "rounded-b-xl" : "pt-6 px-6 pb-4",
                    "overflow-y-auto",
                    (size === "large" || size === "xl") && "flex-grow",
                    size === "large" && "tablet:flex-grow-0"
                )}
            >
                {children}
            </div>
            <ModalFooter footerButtons={footerButtons} />
        </DialogPanel>
    );

    return (
        <Dialog
            open={open}
            onClose={() => headerProps.onClose?.()}
            className={twMerge(
                "fixed flex flex-col w-full h-full z-50 left-0 top-0 overflow-auto bg-charcoal/70 tablet:p-8 overscroll-none",
                overlayClassName
            )}
        >
            {size === "small" || size === "medium" ? (
                // Slide in small/medium modals on mobile
                <Transition as={Fragment} appear show={open}>
                    <TransitionChild
                        as={Fragment}
                        enter={`transform tablet:tranform-none transition tablet:transition-none ${DRAWER_EASE_IN}`}
                        // Cancel out move when not on mobile
                        enterFrom="translate-y-full tablet:translate-y-0"
                        enterTo="translate-y-0"
                        leave={`transform tablet:tranform-none transition tablet:transition-none ${DRAWER_EASE_OUT}`}
                        leaveFrom="translate-y-0 tablet:translate-y-full"
                        leaveTo="translate-y-full"
                    >
                        {modalContents}
                    </TransitionChild>
                </Transition>
            ) : (
                modalContents
            )}
        </Dialog>
    );
};
