import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import { useAuth } from "hooks/use-auth";
import ClearLayout from "components/ClearLayout";
import { URI_LOGIN } from "constants/urls";
import Loader from "./Loader";

export default function PrivateRoute({ unProtectedRoutes, children }: any) {
    const router = useRouter();
    const { token } = useAuth();
    const { status } = useSession();
    const pathIsProtected = unProtectedRoutes.indexOf(router?.pathname) === -1;

    useEffect(() => {
        const backLinkURI = router?.asPath ? `?from=${router.asPath}` : "";

        if (pathIsProtected && status === "unauthenticated" && router.pathname !== URI_LOGIN) {
            router.push(`${URI_LOGIN}${backLinkURI}`);
        }
    });

    return !token && pathIsProtected ? (
        <ClearLayout>
            <Loader isSpin={true} />
        </ClearLayout>
    ) : (
        children
    );
}
