import { DynamicForm } from "components/form/DynamicForm";
import REQUEST_SUPPLIER_LOGIN_FORM_SCHEMA from "components/form/schemas/RequestSupplierLoginSchema.json";

interface Props {
    onDone?: () => void;
    defaultValues?: { [key: string]: number | string };
}

export const RequestSupplierLoginForm = ({ onDone, defaultValues }: Props) => {
    return (
        <DynamicForm
            schema={REQUEST_SUPPLIER_LOGIN_FORM_SCHEMA}
            onDone={onDone}
            defaultValues={defaultValues}
        />
    );
};
