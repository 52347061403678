import { useFormikContext } from "formik";
import { SubmitScreen } from "components/form/pages/SubmitScreen";
import { Textarea } from "../common/v2/Textarea";
import { Input } from "../common/v2/Input";
import { FormProperties, PageSchema } from "types/form/schema";
import { twMerge } from "tailwind-merge";
import { DataboundTypedown } from "components/common/v2/widgets/DataboundTypedown";
import getConfig from "next/config";
import { SingleSelect } from "components/common/v2/select";

const Pages: { [key: string]: (props: any) => JSX.Element } = {
    SubmitScreen
};

const FieldComponents: { [key: string]: (props: any) => any } = {
    DataboundTypedown
};

export const Page = ({
    schema,
    properties,
    submittedFirstTime
}: {
    schema: PageSchema;
    properties: FormProperties;
    submittedFirstTime: boolean;
}) => {
    const { publicRuntimeConfig } = getConfig();
    const { values, errors, setFieldValue, touched } = useFormikContext<{
        [key: string]: number | string;
    }>();

    if (schema?.component && Pages[schema.component]) {
        const PageComponent = Pages[schema.component];
        return <PageComponent schema={schema} properties={properties} />;
    }

    return (
        <div className="flex flex-wrap gap-x-4">
            {schema.properties?.map((propertyId: string) => {
                const value: string | number | undefined = values[propertyId];
                const currentProperty = properties[propertyId];
                const {
                    title,
                    placeholder,
                    component,
                    oneOf,
                    className,
                    required,
                    componentProps,
                    default: defaultValue
                } = currentProperty;
                const styleClass = twMerge("mb-6 grow w-full", className);
                const updateValue = (value?: string | number | undefined) => {
                    setFieldValue(propertyId, value, true);
                };
                const error =
                    touched[propertyId] || submittedFirstTime ? errors[propertyId] : undefined;

                if (oneOf || component === "Typedown") {
                    return (
                        <div className={styleClass} key={propertyId}>
                            <SingleSelect
                                id={propertyId}
                                options={
                                    oneOf?.map(({ id, name }) => ({
                                        name,
                                        value: `${id}`
                                    })) || []
                                }
                                label={title}
                                placeholder={placeholder}
                                value={value ? `${value}` : undefined}
                                onChange={updateValue}
                                error={error}
                                data-testid={propertyId}
                                required={required}
                                variant={component === "Typedown" ? "search" : "default"}
                                {...(defaultValue
                                    ? {
                                          defaultSearchValue: defaultValue
                                              ? `${defaultValue}`
                                              : undefined
                                      }
                                    : {})}
                            />
                        </div>
                    );
                }
                if (component === "Textarea") {
                    return (
                        <div className={styleClass} key={propertyId}>
                            <Textarea
                                id={propertyId}
                                label={title}
                                placeholder={placeholder}
                                value={value}
                                onChange={updateValue}
                                error={error}
                                required={required}
                            />
                        </div>
                    );
                }

                const FieldComponent =
                    component && FieldComponents[component] ? FieldComponents[component] : Input;

                return (
                    <div className={styleClass} key={propertyId}>
                        <FieldComponent
                            id={propertyId}
                            label={title}
                            placeholder={placeholder}
                            value={value}
                            onChange={updateValue}
                            error={error}
                            required={required}
                            {...componentProps}
                            {...(component === "DataboundTypedown"
                                ? {
                                      url: `${publicRuntimeConfig.nextBase}${
                                          componentProps?.url || ""
                                      }`,
                                      variant: "search"
                                  }
                                : {})}
                        />
                    </div>
                );
            })}
        </div>
    );
};
