import { useEffect, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Link from "next/link";
import { useFormikContext } from "formik";
import { ArrowSquareOut } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import PortalAnalytics from "PortalAnalytics";
import Banner from "components/common/Banner";
import {
    Button,
    baseButtonStyles,
    getButtonSizeStyle,
    getButtonStyles
} from "components/common/v2/button/Button";
import { getURL } from "utils/url";
import { DisplayError, If, PageSchema, Schema } from "types/form/schema";

const getNextPage = (
    schema: Schema,
    pageSchema: PageSchema,
    values: { [key: string]: string | number | null }
): number => {
    if (pageSchema.allOf) {
        const condition = pageSchema.allOf.find((logic: If) => {
            if (!Array.isArray(logic.if.oneOf)) return false;
            return !!logic.if.oneOf.find(
                (currentCondition: { [key: string]: string | number | null }) => {
                    for (let key in currentCondition) {
                        if (currentCondition.hasOwnProperty(key)) {
                            if (values[key] === currentCondition[key]) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
            );
        });
        if (!condition) return -1;
        const nextPageSlug = condition.then?.next;
        return schema.pages.findIndex(({ slug }: { slug: string }) => slug === nextPageSlug) || -1;
    }
    return -1;
};

const CancelButton = ({ onClick, className }: { onClick?: () => void; className?: string }) => {
    if (!onClick) return null;
    return (
        <Button variant="text" onClick={onClick} className={className}>
            Cancel
        </Button>
    );
};

interface Props {
    schema: Schema;
    currentPage: number;
    onPageChange: (page: number) => void;
    onSubmit: (values: { [key: string]: number | string }) => void;
    onDone?: () => void;
    error?: DisplayError;
    isSubmitting?: boolean;
    setSubmittedFirstTime?: (submitted: boolean) => void;
}
export const Navigation = ({
    schema,
    currentPage,
    onPageChange,
    onSubmit,
    onDone,
    error,
    isSubmitting = false,
    setSubmittedFirstTime
}: Props) => {
    const pathname = usePathname() ?? "";
    const searchParams = useSearchParams() ?? new URLSearchParams();
    const formURL = getURL(pathname, searchParams);
    const trackingProperties = {
        url: formURL,
        wizard_title: schema.title
    };
    const currentPageSchema = schema.pages[currentPage];
    const { values, validateForm } = useFormikContext<{
        [key: string]: number | string;
    }>();
    const url = useMemo(() => {
        if (currentPageSchema?.nextButton?.override) {
            const propertyId = currentPageSchema?.nextButton?.override?.id;
            const attribute = currentPageSchema?.nextButton?.override?.value || "name";

            const propertyCondition = Array.isArray(schema.properties[propertyId].oneOf)
                ? schema.properties[propertyId].oneOf
                : [];

            const currentValue = values[propertyId];
            const selectedProperty:
                | { id: string | number; name: string; url?: string }
                | undefined = propertyCondition?.find(({ id }: { id: string | number }) => {
                return id === currentValue;
            });
            const url = selectedProperty?.[attribute as keyof typeof selectedProperty];

            return `${url}`;
        }
        return undefined;
    }, [currentPageSchema, values]);
    useEffect(() => {
        PortalAnalytics.track("wizard__track_started", trackingProperties);
    }, []);
    const onNext = async () => {
        if (setSubmittedFirstTime) setSubmittedFirstTime(true);
        const result = await validateForm(values);
        if (JSON.stringify(result) === "{}") {
            if (currentPageSchema?.nextButton?.text === "Close" && onDone) {
                PortalAnalytics.track("wizard__track_close", trackingProperties);
                onDone();
            } else if (currentPageSchema?.nextButton?.type === "submit") {
                PortalAnalytics.track("wizard__track_submit", trackingProperties);
                onSubmit(values);
            } else {
                PortalAnalytics.track("wizard__track_next", trackingProperties);
                let next = getNextPage(schema, currentPageSchema, values);
                if (next > -1) next = next;
                if (schema.pages.length <= currentPage + 1) next = currentPage;
                if (next < 1) next = currentPage + 1;
                onPageChange(next);
            }
        }
    };
    if (url) {
        const isOutsideLink = !url?.includes("https://advisor.fora.travel");
        if (isOutsideLink) {
            return (
                <div className="action-button-bar">
                    <CancelButton onClick={onDone} />
                    <Link
                        href={url}
                        target="_blank"
                        className={twMerge(
                            baseButtonStyles,
                            getButtonSizeStyle("medium"),
                            "text-link hover:underline"
                        )}
                    >
                        <span>{currentPageSchema?.nextButton?.text || "NEXT"}</span>
                        <ArrowSquareOut className="ml-1" size={18} />
                    </Link>
                </div>
            );
        }
        return (
            <div className="action-button-bar">
                <CancelButton onClick={onDone} />
                <Link href={url} className={getButtonStyles()}>
                    {currentPageSchema?.nextButton?.text || "NEXT"}
                </Link>
            </div>
        );
    }
    return (
        <>
            {error && (
                <div className="mb-6">
                    <Banner content={error.description} type={error.icon} />
                </div>
            )}

            <div className="action-button-bar justify-end">
                {currentPageSchema?.nextButton?.text !== "Close" && (
                    <CancelButton
                        onClick={onDone}
                        className={currentPageSchema?.prevButton?.className}
                    />
                )}
                <Button
                    data-testid={currentPageSchema?.nextButton?.text || "next-button"}
                    onClick={onNext}
                    name={currentPageSchema?.nextButton?.text || "NEXT"}
                    aria-label={currentPageSchema?.nextButton?.text || "NEXT"}
                    disabled={isSubmitting && currentPageSchema?.nextButton?.type === "submit"}
                    className={currentPageSchema?.nextButton?.className}
                >
                    {currentPageSchema?.nextButton?.text || "NEXT"}
                </Button>
            </div>
        </>
    );
};
