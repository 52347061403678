import React, { ReactNode, Fragment, useState } from "react";
import { useDrag } from "@use-gesture/react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { X } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import { Button, ButtonVariantT } from "./button/Button";
import useWindowDimensions from "hooks/useWindowDimensions";

const TRANSLATE_FULL = { x: "translate-x-full", y: "translate-y-full" };
const TRANSLATE_0 = { x: "translate-x-0", y: "translate-y-0" };

export const DRAWER_EASE_IN = "ease-in-out duration-300";
export const DRAWER_EASE_OUT = "ease-in-out duration-[400ms]";

const TIMEOUT = 400;
const DRAG_DISTANCE_TO_CLOSE_PX = 30;

type DrawerPlacement = "right" | "bottom";

export interface DrawerProps {
    /** Content of the drawer */
    children: ReactNode;
    /** A method that will be fired when the X close button is pressed */
    onClose?: () => void;
    /** The direction from which the drawer slides out from  default = "bottom" */
    placement?: DrawerPlacement;
    /** If true will open from bottom on mobile - default = false */
    useResponsivePlacement?: boolean;
    /** If true, the drawer will use content's height - default = false */
    useContentHeight?: boolean;
}

export const Drawer = ({
    children,
    onClose,
    placement = "bottom",
    useResponsivePlacement = false,
    useContentHeight = false
}: DrawerProps) => {
    const { isMobile } = useWindowDimensions();
    const [dragOffset, setDragOffset] = useState<number>(0);
    const [show, setShow] = useState(true);

    const responsivePlacement = isMobile ? "bottom" : placement;
    const drawerPlacement = useResponsivePlacement ? responsivePlacement : placement;
    const isBottomDrawer = drawerPlacement === "bottom";

    const bottomDrawerClasses = `shadow-[4px_0_32px_disabled] fixed tablet:h-[dvh] inset-x-0 bottom-0 w-full ${
        useContentHeight ? "min-h-[400px]" : "h-full top-16"
    } bg-white flex flex-col overflow-hidden rounded-t-xl`;
    const rightDrawerClasses =
        "fixed h-[100dvh] bottom-0 top-0 right-0 w-4/5 min-w-[335px] max-w-[492px] bg-white flex flex-col overflow-hidden pt-6";
    const placementConfigs: {
        axis: "x" | "y";
        drawerClasses: string;
        drawerContentClasses: string;
        transform: string;
        closeButtonClasses: string;
        closeButtonVariant: ButtonVariantT;
    } = {
        axis: isBottomDrawer ? "y" : "x",
        drawerClasses: isBottomDrawer ? bottomDrawerClasses : rightDrawerClasses,
        drawerContentClasses: isBottomDrawer ? "px-4 pb-4" : "px-6 pb-6",
        transform: isBottomDrawer
            ? `translate(0, ${dragOffset}px)`
            : `translate(${dragOffset}px, 0)`,
        closeButtonClasses: isBottomDrawer
            ? "absolute hidden tablet:block right-6"
            : "block px-6 pb-4",
        closeButtonVariant: isBottomDrawer ? "text" : "secondary"
    };

    const bind = useDrag(({ down, movement: [mx, my], type }) => {
        const movementAxis = isBottomDrawer ? my : mx;
        if (type === "pointerup" && dragOffset > DRAG_DISTANCE_TO_CLOSE_PX) {
            closeDrawer();
            return;
        }
        setDragOffset(Math.max(down ? movementAxis : 0, 0));
    });

    const closeDrawer = async () => {
        setDragOffset(0);
        setShow(false);
        await new Promise(res => setTimeout(res, TIMEOUT));
        onClose?.();
    };

    return (
        <Dialog onClose={closeDrawer} open>
            <DialogPanel>
                <Transition
                    as="div"
                    appear={true}
                    show={show}
                    className="relative touch-none"
                    {...bind()}
                >
                    <TransitionChild
                        as={Fragment}
                        enter={DRAWER_EASE_IN}
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave={DRAWER_EASE_OUT}
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className="fixed inset-0 bg-charcoal/70 transition-opacity"
                            onClick={closeDrawer}
                        />
                    </TransitionChild>
                    <TransitionChild
                        as={Fragment}
                        enter={`transform transition ${DRAWER_EASE_IN}`}
                        enterFrom={TRANSLATE_FULL[placementConfigs.axis]}
                        enterTo={TRANSLATE_0[placementConfigs.axis]}
                        leave={`transform transition ${DRAWER_EASE_OUT}`}
                        leaveFrom={TRANSLATE_0[placementConfigs.axis]}
                        leaveTo={TRANSLATE_FULL[placementConfigs.axis]}
                    >
                        <div
                            className={placementConfigs.drawerClasses}
                            style={dragOffset ? { transform: placementConfigs.transform } : {}}
                        >
                            <div className="flex my-1">
                                <div
                                    className={twMerge(
                                        "shrink-0",
                                        placementConfigs.closeButtonClasses
                                    )}
                                >
                                    <Button
                                        onClick={closeDrawer}
                                        data-testid="right-drawer-close-button"
                                        LeftIcon={X}
                                        variant={placementConfigs.closeButtonVariant}
                                    />
                                </div>
                                {isBottomDrawer && (
                                    <span
                                        role="button"
                                        data-testid="bottom-drawer-close-button-mobile"
                                        className="cursor-pointer shrink-0 text-center border-2 rounded-[20px] w-[64px] my-2 mx-auto border-primary tablet:invisible"
                                        onClick={closeDrawer}
                                    />
                                )}
                            </div>

                            <div className="overflow-y-auto w-full h-full">
                                <div
                                    className={twMerge(
                                        "w-full tablet:max-w-[900px] flex flex-col bg-white shadow-card mx-auto",
                                        placementConfigs.drawerContentClasses
                                    )}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </Transition>
            </DialogPanel>
        </Dialog>
    );
};
