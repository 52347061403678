export const getRedirectURL = (url: string, data: { [key: string]: string | number }): string => {
    const re = /[^{\}]+(?=})/g;
    const strings = url.matchAll(re);
    return (
        Array.from(strings).reduce((acc, match) => {
            const prop = match[0];
            return acc.replace(`{${prop}}`, data[prop] ? `${data[prop]}` : "");
        }, url) || url
    );
};
