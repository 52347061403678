export enum BannerStyleTypes {
    INFO = "info",
    ALERT = "alert",
    RESTRICTED = "restricted",
    BRAND_ALERT = "brand-alert",
    WARNING_FLAG = "warningflag", // TODO: Come up with better naming machanism
    INFO_WARNING = "info-warning",
    ERROR_BODY_BORDER = "error-body-border",
    INFO_BLUE = "info-blue"
}
