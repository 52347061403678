import React from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "../button/Button";
import { ModalProps } from "./modal-types";

type ModalFooterProps = Pick<ModalProps, "footerButtons">;

/** The footer of a Modal, with one or two buttons (not for external use) */
export const ModalFooter = ({ footerButtons }: ModalFooterProps): JSX.Element | null => {
    if (!footerButtons) return null;

    const { secondary, primary, vertical } = footerButtons;

    const secondaryButton = secondary && <Button variant="text" {...secondary} />;

    const primaryButton = <Button variant="primary" {...primary} />;

    return (
        <footer
            className={twMerge(
                "flex p-4 border-t border-primary",
                vertical || !secondary
                    ? "flex-col-reverse gap-y-2 [&>*]:flex-grow"
                    : "justify-between"
            )}
        >
            {secondaryButton}
            {primaryButton}
        </footer>
    );
};
