import { URL_BASE } from "constants/urls";
import { useSWRGlobalMutation } from "contexts/SWRContext";

export const useUpdateDefaultCurrency = ({
    onSuccessCallback,
    onErrorCallback
}: {
    onSuccessCallback?: () => void;
    onErrorCallback?: (e: Error) => void;
}) => {
    const url = `${URL_BASE}profile/`;

    const {
        error,
        trigger: saveCurrency,
        isMutating: isSaving
    } = useSWRGlobalMutation(
        { url: url, method: "PATCH" },
        {
            onSuccess: () => {
                onSuccessCallback?.();
            },
            onError: e => {
                onErrorCallback?.(e);
            }
        }
    );

    return { error, saveCurrency, isSaving };
};
