import React from "react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useAuth } from "hooks/use-auth";
import Loader from "components/common/Loader";
import fireTheConfetti from "utils/fire-the-confetti";
import { getNumberOfBookingsForUser } from "api/bookings/bookings";
import { useMarkLessonCompleted } from "hooks/training/useMarkLessonCompleted";
import { Modal } from "components/common/v2/modal/Modal";
import { useRouter } from "next/navigation";
//
export const CertificationQuizSuccess = ({ closeModal }: { closeModal: () => void }) => {
    const router = useRouter();
    const { token } = useAuth();

    const { markLessonAsComplete } = useMarkLessonCompleted({
        track: { slug: "certified" },
        module: { slug: "certified-quiz" }
    });

    useEffect(() => {
        //NOTE: Should we hardcode the data or load it
        markLessonAsComplete({
            id: 239,
            slug: "take-the-certified-quiz",
            is_completed: false,
            internal_name: "",
            name: "",
            lesson_type: "",
            complete_time: 10,
            sequence: 1
        });
        fireTheConfetti();
    }, []);

    // In the case where the user passed the certification quiz (success state) we need to get num bookings to know if they've completed their first
    // booking as the message will differ depending on that.
    // We don't need this data in the failure state, so the ordering of the ternary logic in the return statement is important to ensure we only show
    // the loader when we need to (i.e. if the user succeeded but we don't have the booking data yet)
    const [numBookings, setNumBookings] = useState<number | undefined>(undefined);
    const [bookingDataLoading, setBookingDataLoading] = useState<boolean>(false);
    useEffect(() => {
        if (!token) return;
        setBookingDataLoading(true);
        getNumberOfBookingsForUser(token).then(res => {
            setNumBookings(res);
            setBookingDataLoading(false);
        });
    }, [token]);
    const hasCompletedFirstBooking = numBookings !== undefined && numBookings > 0;

    if (bookingDataLoading) {
        return <Loader />;
    }
    return (
        <Modal
            title="Congrats! You passed the Certified Quiz!"
            open
            onClose={closeModal}
            footerButtons={{
                primary: hasCompletedFirstBooking
                    ? { onClick: closeModal, children: "Got it" }
                    : {
                          onClick: () => router.push("/partners"),
                          children: "Let’s get booking"
                      }
            }}
        >
            <div className="mb-6">
                <div className="text-medium font-medium text-secondaryDark flex flex-col gap-4">
                    {hasCompletedFirstBooking ? (
                        <p>
                            Look out for an email from us with more information on your new
                            certification and badge!
                        </p>
                    ) : (
                        <>
                            <p>
                                To earn your certification, you also need to make your first booking
                                through the Portal.
                            </p>
                            <p>
                                Once you complete your first booking, look out for an email from us
                                with more information on your new certification and badge!
                            </p>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};
