import React, { useState } from "react";
import { useSingleSelectContext } from "./select-context";
import { Combobox } from "@headlessui/react";
import { SelectInput } from "./SelectInput";
import { SingleSelectContainer } from "./SingleSelectContainer";
import { SelectMobile } from "./SelectMobile";
import { selectInputBaseStyles } from "./const";
import { CaretDown } from "@phosphor-icons/react";
import { twMerge } from "tailwind-merge";
import { SelectInputContainer } from "./SelectInputContainer";

const SingleSelectMobileInput = ({ onClick }: { onClick: () => void }) => {
    const props = useSingleSelectContext();
    const { variant, displayValue, value, placeholder, error, disabled, autoWidth } = props;
    useSingleSelectContext();

    if (variant === "search") {
        return (
            <Combobox value={value}>
                <SelectInput onClick={onClick} open={false} readOnly />
            </Combobox>
        );
    }

    return (
        /** had to use a a button here instead of ListboxButton since Listbox options become buggy on select
         * (have to double click to select) when there is more than one button on the screen*/
        <SelectInputContainer data-testid="single-select-input" {...props}>
            <button
                data-testid="single-select-input"
                type="button"
                onClick={onClick}
                className={twMerge(
                    selectInputBaseStyles,
                    "min-h-12",
                    placeholder && !displayValue && "text-tertiary",
                    error && "border-error focus:border-error",
                    disabled && "text-tertiary hover:bg-white",
                    autoWidth && "w-auto block"
                )}
            >
                <p className="mr-6 text-wrap break-words">{displayValue || placeholder}</p>
                <CaretDown
                    size={15}
                    className={twMerge(
                        "absolute right-4 top-1/2 transform -translate-y-1/2",
                        disabled && "text-tertiary"
                    )}
                />
            </button>
        </SelectInputContainer>
    );
};

export const SingleSelectMobile = () => {
    const { label, required, disabled } = useSingleSelectContext();
    const [showModal, setShowModal] = useState(false);
    return (
        <SelectMobile
            showModal={showModal}
            label={label}
            required={required}
            closeModal={() => setShowModal(false)}
            selectInput={
                <SingleSelectMobileInput
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        setShowModal(true);
                    }}
                />
            }
        >
            <SingleSelectContainer closeOnSelect={() => setShowModal(false)} />
        </SelectMobile>
    );
};
