import { Combobox, ListboxButton, Listbox, ListboxOption, ListboxOptions } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { Check, CaretDown } from "@phosphor-icons/react";
import { SelectInput } from "./SelectInput";
import { SelectOptions } from "./SelectOptions";
import { useSingleSelectContext } from "./select-context";
import {
    selectOptionsBaseStyles,
    selectInputBaseStyles,
    selectOptionsContainer,
    checkMarkStyles
} from "./const";
import { SingleSelectActionButton } from "./SingleSelectActionButton";
import { filterByName } from "./MultiSelectDropdown";
import { SelectInputContainer } from "./SelectInputContainer";
import { MultiSelectOption } from "./types";

interface Props {
    /** for use on mobile to close the select on the modal */
    closeOnSelect?: () => void;
}

export const SingleSelectButton = ({
    open,
    onClick,
    isHidden = false
}: {
    open: boolean;
    isHidden?: boolean;
    onClick?: () => void;
}) => {
    const props = useSingleSelectContext();
    const { displayValue, placeholder, error, disabled, autoWidth, containerClassName } = props;

    return (
        <SelectInputContainer
            {...props}
            error={error}
            className={twMerge(isHidden && "invisible h-0 mt-2", containerClassName)}
        >
            <ListboxButton
                // for some reason ListboxButton accepts a placeholder but doesn't render it :/ putting it here for tests
                placeholder={placeholder}
                className={twMerge(
                    selectInputBaseStyles,
                    "min-h-12",
                    isHidden && "invisible h-0 p-0",
                    placeholder && !displayValue && "text-tertiary",
                    error && "border-error focus:border-error",
                    autoWidth && "w-auto block"
                )}
                onClick={() => onClick?.()}
                disabled={disabled}
                data-testid={`select-button${
                    placeholder ? `-${placeholder.toLocaleLowerCase().split(" ").join("-")}` : ""
                }`}
            >
                <p className="mr-6 text-wrap break-words">{displayValue || placeholder}</p>

                <CaretDown
                    size={15}
                    className={twMerge(
                        "absolute right-4 top-1/2 transform -translate-y-1/2",
                        open && "rotate-180",
                        disabled && "text-tertiary"
                    )}
                />
            </ListboxButton>
        </SelectInputContainer>
    );
};

export function SingleSelectDefault({ closeOnSelect }: Props) {
    const {
        value,
        showClearButton,
        actionButtonLabel,
        onActionButtonClick,
        options,
        isMobile,
        onChange,
        disabled,
        autoWidth
    } = useSingleSelectContext();

    return (
        <Listbox
            disabled={disabled}
            key={value}
            value={value}
            onChange={val => {
                onChange(val);
                closeOnSelect?.();
            }}
        >
            {({ open }) => (
                <>
                    <SingleSelectButton open={open} isHidden={isMobile} />

                    <ListboxOptions
                        static={isMobile}
                        transition
                        anchor={
                            !isMobile && {
                                to: "bottom",
                                gap: 4
                            }
                        }
                        className={twMerge(
                            selectOptionsContainer,
                            `z-[60]`, //z-60 was added to support SingleSelect in modals,
                            !autoWidth && "w-[var(--button-width)]",
                            !isMobile && autoWidth && "max-w-[var(--button-width)]",
                            isMobile && `border-0 p-0 -mt-6 w-full`
                        )}
                    >
                        {showClearButton && value && (
                            <ListboxOption
                                key="clear"
                                value=""
                                className={twMerge(
                                    selectOptionsBaseStyles,
                                    "text-secondary font-normal"
                                )}
                            >
                                - Clear selection -
                            </ListboxOption>
                        )}
                        {options.map(option => (
                            <ListboxOption
                                onClick={() => closeOnSelect?.()}
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                                className={twMerge(selectOptionsBaseStyles, "justify-between")}
                            >
                                {option.name}
                                <Check className={checkMarkStyles} />
                            </ListboxOption>
                        ))}
                        {actionButtonLabel && onActionButtonClick && (
                            <SingleSelectActionButton
                                isMobile={isMobile}
                                onClick={() => {
                                    closeOnSelect?.();
                                    onActionButtonClick();
                                }}
                                label={actionButtonLabel}
                            />
                        )}
                    </ListboxOptions>
                </>
            )}
        </Listbox>
    );
}

const getFilteredOptions = (
    options: MultiSelectOption[],
    query: string,
    defaultSearchValue?: string
): MultiSelectOption[] => {
    if (query === "") return options;
    const filteredOptions =
        options?.filter(option => {
            return filterByName(option.name, query);
        }) || [];
    if (defaultSearchValue && filteredOptions?.length < 1) {
        const defaultResult = options.find(option => option.value === defaultSearchValue);
        return defaultResult ? [defaultResult] : [];
    }
    return filteredOptions;
};

export const SingleSelectContainer = ({ closeOnSelect }: Props): JSX.Element => {
    const {
        value = "",
        showClearButton = false,
        actionButtonLabel,
        onActionButtonClick,
        options,
        onChange,
        query,
        onSearch,
        isMobile,
        variant,
        disabled,
        autoWidth,
        onClear,
        defaultSearchValue
    } = useSingleSelectContext();

    const filteredOptions = getFilteredOptions(options, query, defaultSearchValue);

    if (variant === "search") {
        return (
            <Combobox
                disabled={disabled}
                immediate
                value={value}
                onChange={val => {
                    // so the value doesnt get cleared when a user clears the search input
                    if (query === "") {
                        onChange(val ?? "");
                    } else {
                        val && onChange(val ?? "");
                    }
                    // if null dont close
                    val && closeOnSelect?.();
                }}
                onClose={() => onSearch("")}
            >
                {({ open }) => (
                    <>
                        <SelectInput open={open} isMobile={isMobile} />
                        <SelectOptions
                            options={filteredOptions}
                            clearSelection={() => {
                                onChange("");
                                onClear?.();
                            }}
                            showClearButton={showClearButton && !!value}
                            isMobile={isMobile}
                            isSingleSelect
                            actionButtonLabel={actionButtonLabel}
                            onActionButtonClick={onActionButtonClick}
                            closeOnSelect={closeOnSelect}
                            autoWidth={autoWidth}
                        />
                    </>
                )}
            </Combobox>
        );
    }
    return <SingleSelectDefault closeOnSelect={closeOnSelect} />;
};
