import { useFormikContext } from "formik";
import { FormProperties, PageSchema } from "types/form/schema";

interface Props {
    schema: PageSchema;
    properties: FormProperties;
}
export const SubmitScreen = ({ schema, properties }: Props) => {
    const { values } = useFormikContext<{
        [key: string]: number | string;
    }>();
    let descriptionWithDynamicValues = schema?.description;
    if (descriptionWithDynamicValues) {
        for (const property in values) {
            const splitValue: string[] = descriptionWithDynamicValues?.split(`{${property}}`);

            if (splitValue.length > 1) {
                const propertyId = values[property];
                const propertyValue = properties?.[property].oneOf
                    ? properties?.[property].oneOf?.find(
                          ({ id }: { id: string | number }) => `${id}` === `${propertyId}`
                      )?.name
                    : `${propertyId}`;
                descriptionWithDynamicValues = splitValue.join(propertyValue);
            } else {
                descriptionWithDynamicValues = splitValue.join("");
            }
        }
    }
    return (
        <div
            className="form-help pb-6"
            dangerouslySetInnerHTML={{
                __html: descriptionWithDynamicValues || ""
            }}
        />
    );
};
