/** pulled from the tailwind.config.js **/
export const WINDOW_SIZES = {
    x375: 375,
    xfold: 376,
    x390: 390,
    xss: 500,
    min580: 580,
    modalFS: 585,
    x640: 640,
    md: 744,
    tablet: 768,
    min888: 888,
    sidebarW: 1020,
    desktop: 1024,
    min1160: 1160,
    min1280: 1280,
    min1306: 1306,
    x1290: 1330,
    min1440: 1440,
    x1478: 1478,
    x1560: 1560,
    min1600: 1600
};

const MIN_WIDTHS = {
    x390: { min: `${WINDOW_SIZES.x390}px` },
    md: { min: `${WINDOW_SIZES.md}px` },
    tablet: { min: `${WINDOW_SIZES.tablet}px` },
    min580: { min: `${WINDOW_SIZES.min580}px` },
    desktop: { min: `${WINDOW_SIZES.desktop}px` },
    min1160: { min: `${WINDOW_SIZES.min1160}px` },
    min1280: { min: `${WINDOW_SIZES.min1280}px` },
    x1290: { min: `${WINDOW_SIZES.x1290}px` },
    min1440: { min: `${WINDOW_SIZES.min1440}px` },
    x1478: { min: `${WINDOW_SIZES.x1478}px` },
    x1560: { min: `${WINDOW_SIZES.x1560}px` },
    min1600: { min: `${WINDOW_SIZES.min1600}px` }
};

const MAX_WIDTHS = {
    x375: { max: `${WINDOW_SIZES.x375}px` },
    xfold: { max: `${WINDOW_SIZES.xfold}px` },
    xss: { max: `${WINDOW_SIZES.xss}px` },
    x640: { max: `${WINDOW_SIZES.x640}px` },
    sidebarW: { max: `${WINDOW_SIZES.sidebarW}px` }
};

const WIDTHS_PX = {
    min888: `${WINDOW_SIZES.min888}px`,
    min1306: `${WINDOW_SIZES.min1306}px`,
    modalFS: `${WINDOW_SIZES.modalFS}px`,
    xssPx: `${WINDOW_SIZES.xss}px`,
    w580Px: `${WINDOW_SIZES.min580}px`,
    tabletPx: `${WINDOW_SIZES.tablet}px`
};

export const SCREENS = {
    ...MIN_WIDTHS,
    ...MAX_WIDTHS,
    ...WIDTHS_PX
};
