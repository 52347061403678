import PortalAnalytics from "PortalAnalytics";
import {
    IIssueCategory,
    IIssueType,
    IIssueSeverity,
    ReportIssueMetadata
} from "lib/types/issues.types";

export function trackReportIssueClicked() {
    PortalAnalytics.track("report_issue__issue_button_clicked");
}

export function trackReportIssueCategoryClicked(issueCategory: IIssueCategory) {
    PortalAnalytics.track("report_issue__issue_category_clicked", {
        category_id: issueCategory.id,
        category: issueCategory.category,
        category_display_text: issueCategory.display_text
    });
}

export function trackReportIssueTypeClicked(issueType: IIssueType) {
    PortalAnalytics.track("report_issue__issue_type_clicked", {
        reason_id: issueType.id,
        reason: issueType.slug,
        reason_display_text: issueType.display_text
    });
}

export function trackReportIssueSeverityClicked(issueSeverity: IIssueSeverity) {
    PortalAnalytics.track("report_issue__issue_severity_clicked", {
        severity_id: issueSeverity.id,
        severity: issueSeverity.severity,
        severity_display_text: issueSeverity.display_text
    });
}

export function trackReportIssueCancelClicked(metadata: ReportIssueMetadata) {
    PortalAnalytics.track("report_issue__issue_cancel_clicked", metadata);
}

export function trackReportIssueSubmitClicked(metadata: ReportIssueMetadata) {
    PortalAnalytics.track("report_issue__issue_submit_clicked", metadata);
}
