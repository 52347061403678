export const selectInputBaseStyles =
    "relative text-medium cursor-pointer w-full text-primary text-left border border-primary rounded-xl placeholder:text-tertiary px-4 py-3 hover:bg-surface-1-hover data-[open]:hover:bg-white focus:ring-0 focus:border-selected data-[open]:border-selected bg-white data-[disabled]:text-tertiary hover:data-[disabled]:bg-white";

export const selectOptionsBaseStyles =
    "relative cursor-pointer text-medium text-primary rounded-sm group flex gap-x-2 mb-2 bg-white data-[focus]:bg-surface-1-hover data-[disabled]:text-tertiary data-[disabled]:cursor-default data-[disabled]:hover:bg-white hover:bg-surface-1-hover last-of-type:mb-0 p-2";

export const selectOptionsContainer =
    "relative p-2 border border-primary rounded-xl bg-surface-1 !max-h-[344px] empty:invisible max-h-[50svh] tablet:shadow-[0_4px_12px_-4px_rgba(0,0,0,0.15)]";

export const checkMarkStyles = "invisible size-5 group-data-[selected]:visible shrink-0";
