import { UserProfile } from "api/user/profile";
import { URL_USER_PROFILE } from "constants/urls";
import useSWR, { useSWRConfig } from "swr";
import { BLANK_USER, useAuth } from "./use-auth";

export const useGetUserProfile = () => {
    const { token } = useAuth();
    if (!token) {
        return {
            data: null,
            userProfile: BLANK_USER,
            isLoading: false,
            error: { message: "No auth token" },
            reValidateProfileData: () => {}
        };
    }
    const { data, isLoading, error } = useSWR(URL_USER_PROFILE, {
        revalidateOnFocus: false
    });
    const { mutate } = useSWRConfig();

    const userProfile = data ? new UserProfile(data) : BLANK_USER;

    const reValidateProfileData = () => {
        mutate(URL_USER_PROFILE);
    };

    return { data, userProfile, isLoading, error, reValidateProfileData };
};
