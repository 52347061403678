import { DynamicForm } from "components/form/DynamicForm";
import ADD_CLIENT_FORM_SCHEMA from "components/form/schemas/AddClientSchema.json";
import { BannerStyleTypes } from "lib/types/banners.types";
import { useState } from "react";

interface Props {
    onDone?: () => void;
    defaultValues?: { [key: string]: number | string };
}

export const DUPLICATED_ERROR_API_MESSAGE = "Client already exists with this name";
export const AddClientForm = ({ onDone, defaultValues }: Props) => {
    const [skipParameter, setSkipParameter] = useState<{
        [key: string]: boolean;
    }>({});
    const forceSkip = (errorMessage?: string) => {
        if (errorMessage === DUPLICATED_ERROR_API_MESSAGE)
            setSkipParameter({ skip_dup_check: true });
    };
    return (
        <DynamicForm
            schema={ADD_CLIENT_FORM_SCHEMA}
            onDone={onDone}
            onError={forceSkip}
            defaultValues={defaultValues}
            extraParams={skipParameter}
            customErrors={[
                {
                    apiMessage: DUPLICATED_ERROR_API_MESSAGE,
                    error: {
                        description:
                            "A client with this name already exists. Please confirm you are not creating a duplicate client.",
                        icon: BannerStyleTypes.INFO_WARNING
                    }
                }
            ]}
        />
    );
};
