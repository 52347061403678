import { ReactElement } from "react";
import { Warning } from "phosphor-react";
import { twMerge } from "tailwind-merge";

interface Props {
    children?: string | ReactElement;
    className?: string;
}
export const InputError = ({ children, className = "" }: Props) => {
    if (!children) return null;
    return (
        <div className={twMerge(`flex items-center justify-start gap-2 text-error  ${className}`)}>
            <Warning size={24} />
            {children}
        </div>
    );
};
