import useWindowDimensions from "hooks/useWindowDimensions";

import { SingleSelectContextProvider } from "./select-context";
import { SingleSelectContainer } from "./SingleSelectContainer";
import { SingleSelectMobile } from "./SingleSelectMobile";
import { SingleSelectProps } from "./types";

export function SingleSelect({ variant = "default", ...props }: SingleSelectProps) {
    const { isMobile } = useWindowDimensions();

    return (
        <SingleSelectContextProvider {...props} variant={variant}>
            {isMobile ? <SingleSelectMobile /> : <SingleSelectContainer />}
        </SingleSelectContextProvider>
    );
}
