import { createContext, useContext, useMemo, useState } from "react";
import {
    MultiSelectContextT,
    MultiSelectOption,
    MultiSelectProps,
    SingleSelectProps,
    SingleSelectContextT
} from "./types";
import useWindowDimensions from "hooks/useWindowDimensions";

const getDisplayValue = (value: string, options: MultiSelectOption[]) => {
    const found = options.find(option => {
        if (option.options) {
            return option.options.find(option => option.value === value);
        }
        return option.value === value;
    });
    const foundName = found?.options
        ? found.options.find(option => option.value === value)?.name
        : found?.name;

    return foundName ?? "";
};

const useHandleSearch = (onSearch?: (query: string) => void) => {
    const [query, setQuery] = useState("");

    const handleSearch = (query: string) => {
        setQuery(query);
        onSearch?.(query);
    };

    return { query, handleSearch };
};

const commonDefaultContextValues = {
    options: [],
    onChange: () => {},
    label: "",
    displayValue: "",
    query: "",
    isMobile: false,
    placeholder: "",
    onSearch: () => {}
};

const singleSelectContextDefaultValues = {
    ...commonDefaultContextValues,
    variant: "default" as const,
    showClearButton: false,
    onActionButtonClick: () => {},
    actionButtonLabel: "",
    value: ""
};

const SingleSelectContext = createContext<SingleSelectContextT>(singleSelectContextDefaultValues);

export function useSingleSelectContext() {
    return useContext<SingleSelectContextT>(SingleSelectContext);
}

export const SingleSelectContextProvider: React.FC<SingleSelectProps> = ({
    children,
    value,
    onSearch,
    variant = "default",
    autoWidth = false,
    ...props
}) => {
    const { isMobile } = useWindowDimensions();
    const { query, handleSearch } = useHandleSearch(onSearch);

    const displayValue = useMemo(
        () => getDisplayValue(value || "", props.options),
        [value, props.options]
    );
    const showLabelOnDesktop = props.showLabelOnDesktop ?? true;

    return (
        <SingleSelectContext.Provider
            value={{
                ...singleSelectContextDefaultValues,
                query,
                displayValue,
                isMobile,
                value,
                showLabelOnDesktop,
                autoWidth,
                onSearch: handleSearch,
                variant,
                ...props
            }}
        >
            {children}
        </SingleSelectContext.Provider>
    );
};

const MultiSelectContext = createContext<MultiSelectContextT>({
    ...commonDefaultContextValues,
    showClearButton: true,
    values: [],
    showSelected: true
});

export const useMultiSelectContext = () => useContext<MultiSelectContextT>(MultiSelectContext);

export const MultiSelectContextProvider: React.FC<MultiSelectProps> = ({
    children,
    onChange,
    values,
    options,
    onSearch,
    showSelected = true,
    showLabelOnDesktop = true,
    ...props
}) => {
    const { isMobile } = useWindowDimensions();
    const { query, handleSearch } = useHandleSearch(onSearch);

    const displayValue = useMemo(
        () =>
            values.length
                ? `${getDisplayValue(values[0], options)} ${
                      values.length > 1 ? `+ ${values.length - 1}` : ""
                  }`
                : "",
        [values, options]
    );

    return (
        <MultiSelectContext.Provider
            value={{
                options,
                onChange,
                values,
                displayValue: showSelected ? displayValue : "",
                onSearch: handleSearch,
                query,
                isMobile,
                showLabelOnDesktop,
                ...props
            }}
        >
            {children}
        </MultiSelectContext.Provider>
    );
};
